import React from 'react'
import { observer } from "mobx-react";
import { MKCustomFormField } from "@foundation-form";
import { RanksSelector } from "./RanksSelector";

export const RanksSelectorFromField: React.FC = observer(() => {
    return (
        <MKCustomFormField validate={(value) => {
            if (!value.id) {
                return 'שדה חובה'
            }
        }} name='karateDetails.rank' label='דרגה'>
            {({ value, onChange, name }) => <RanksSelector onChange={onChange} name={name} defaultValue={value} />}
        </MKCustomFormField>
    )
})
