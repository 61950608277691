import React from 'react';
import {observer} from 'mobx-react';
import {useRootStore} from "@store";
import Root from "./Root";

export const AppLoader: React.FC = observer(() => {
    const rootStore = useRootStore();
    switch (rootStore.connectionStatus) {

        case 'CONNECTING':
            return (
                <div className='d-flex align-items-center justify-content-center vh-100'>
                    <h3>מתחבר...</h3>
                </div>
            );
        case 'FETCHING_USER_DETAILS':
            return (
                <div className='d-flex align-items-center justify-content-center vh-100'>
                    <h3>טוען נתוני משתמש</h3>
                </div>
            );
        case 'FETCHING_DATA':
            return (
                <div className='d-flex align-items-center justify-content-center vh-100'>
                    <h3>טוען נתונים</h3>
                </div>
            );
        case 'READY':
            return (
                <Root/>
            );
        default:
            return null;
    }
});
