import {makeAutoObservable} from "mobx";


export interface INfcDeviceDTO {
    id: string;
    deviceConnected: boolean;
    cardConnected: boolean;
    writeDataToCard: boolean;
    dataToCard: string;
    cardData: string;
}


export class NfcDeviceStore {

    id: string;
    deviceConnected: boolean;
    cardConnected: boolean;
    writeDataToCard: boolean;
    dataToCard: string;
    cardData: string;

    constructor(nfcDeviceDTO: INfcDeviceDTO) {
        this.init(nfcDeviceDTO)
        makeAutoObservable(this)
    }

    init(nfcDeviceDTO: INfcDeviceDTO) {
        this.id = nfcDeviceDTO.id;
        this.deviceConnected = nfcDeviceDTO.deviceConnected
        this.cardConnected = nfcDeviceDTO.cardConnected
        this.writeDataToCard = nfcDeviceDTO.writeDataToCard
        this.dataToCard = nfcDeviceDTO.dataToCard
        this.cardData = nfcDeviceDTO.cardData
    }
}
