import React from 'react'
import {observer} from "mobx-react";
import {useAuthStore, UserStore} from "@store";
import {MKForm, MKFormField} from '@foundation-form';
import {MKModal} from "@foundation-ui";
import {Button, Col, Row} from "react-bootstrap";
import {DojosSelectorFromField, RanksSelectorFromField} from "@components";

interface IProps {
    user: UserStore;
    open: boolean
    onClose: () => void;
}

interface IInitialValues {
    id: string;
    enabled:boolean
    karateDetails: {
        dojo: {
            id: string;
            name: string
        }
        rank: {
            id: string;
            name: string
        }
        joiningYear: string
        rankYear: string
        specialTrainings:string;
    }
}

export const EditUserKarateDetailsDetailsModal: React.FC<IProps> = observer(({user, open, onClose}) => {

    const authStore = useAuthStore()
    const initialValues: IInitialValues = {
        id: user.id,
        enabled:user.enabled,
        karateDetails: {
            rankYear: user.karateDetails.rankYear,
            joiningYear: user.karateDetails.joiningYear,
            dojo: user.karateDetails.dojo,
            rank: user.karateDetails.rank,
            specialTrainings:user.karateDetails.specialTrainings
        }

    }
    const handleSubmit = async (values: IInitialValues) => {
        await user.updateUserDetails(values)
        onClose()
    }

    return (
        <MKModal
            title='ערוך פרטי קראטה'
            open={open}
            onClose={onClose}
            render={() => {
                return (
                    <MKForm<IInitialValues> onSubmit={handleSubmit} initialValues={initialValues}>
                        {(formik) => {
                            return (
                                <div>
                                    <div className='p-3'>
                                        <Row>
                                            <Col>
                                                <MKFormField label='שנת קבלת דרגה' name='karateDetails.rankYear'/>
                                            </Col>
                                            <Col>
                                                <MKFormField label='שנת הצטרפות' name='karateDetails.joiningYear'/>
                                            </Col>
                                        </Row>
                                        {authStore.user.isDojoAdmin && <Row>
                                            <Col>

                                                <MKFormField label='אימונים מיוחדים' name='karateDetails.specialTrainings'/>
                                            </Col>
                                        </Row>}
                                        <Row>
                                            <Col xs={12}>
                                                <RanksSelectorFromField/>
                                            </Col>
                                            <Col xs={12}>
                                                <DojosSelectorFromField/>
                                            </Col>
                                        </Row>

                                    </div>
                                    <div className='gap-2 p-3 border-top d-flex align-items-center justify-content-end'>
                                        <Button disabled={!formik.dirty} className='text-white ml-1'
                                                type='submit'>שמור</Button>
                                        {formik.dirty && <Button className='text-white ml-1'
                                                                 onClick={() => formik.resetForm()}>איפוס</Button>}
                                        <Button variant='danger' className='text-white' onClick={onClose}>סגור</Button>
                                    </div>
                                </div>
                            )
                        }}
                            </MKForm>
                            )
                        }}
                />

                )
            })
