import React from 'react';
import {ErrorMessage} from 'formik';

interface IProps {
    name: string;
}

export const MKFormErrorMsg: React.FC<IProps> = ({name}) => {
    return (
        <ErrorMessage name={name}>{msg => {
            return <p
                className='text-dangerfw-bold'
                >{msg}</p>

        }}</ErrorMessage>
    )
}
