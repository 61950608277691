import {RootStore, UserStore} from "@store";
import {IApi} from "@api";
import {makeAutoObservable, runInAction} from "mobx";

const throttle = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
    const now = () => new Date().getTime()
    const resetStartTime = () => startTime = now()
    let timeout: NodeJS.Timeout
    let startTime: number = now() - waitFor

    return (...args: Parameters<F>): Promise<ReturnType<F>> =>
        new Promise((resolve) => {
            const timeLeft = (startTime + waitFor) - now()
            if (timeout) {
                clearTimeout(timeout)
            }
            if (startTime + waitFor <= now()) {
                resetStartTime()
                resolve(func(...args))
            } else {
                timeout = setTimeout(() => {
                    resetStartTime()
                    resolve(func(...args))
                }, timeLeft)
            }
        })
}

export class UsersStore {

    user: UserStore

    constructor(private rootStore: RootStore, private api: IApi) {
        makeAutoObservable(this)
    }

    public setUser = (userId: string): void => {
        this.api.getUserDetails(userId, (user) => {
            runInAction(()=>{
                this.user = new UserStore(this.api).init(user)
            })
        })
    }

    public async searchUsers(searchText: string): Promise<UserStore[]> {
        const fn = await throttle(async () => {
            const usersRes = await this.api.searchUsers(searchText)
            return usersRes.map((user) => {
                return new UserStore(this.api).init(user)
            })
        }, 2000)

        return await fn()
    }

    public getUsers():Promise<UserStore[]>{
        return new Promise((resolve,reject)=>{
            this.api.getUsers().then(usersDTO=>resolve(usersDTO.map(user=>new UserStore(this.api).init(user)))).catch(reject)
        })
    }



}
