import React from 'react';
import {observer} from "mobx-react";
import {Nav, Navbar, Container} from "@foundation";
import {NavLink} from "@router";
import {PaymentIcon, UserIcon} from '@assets'
import {useRouteMatch} from 'react-router-dom';

export const MKAdminNavbar: React.FC = observer(() => {

    const routerMatch = useRouteMatch()


    const renderNavLink = () => {
        return (
            <>
                <div className='nav-link d-flex align-items-center'>
                    <PaymentIcon width={20} height={20} className='text-white'/>
                    <NavLink className='nav-link' to={`${routerMatch.path}/payments`}>תשלומי מערכת</NavLink>
                </div>
                <div className='nav-link d-flex align-items-center'>
                    <PaymentIcon width={20} height={20} className='text-white'/>
                    <NavLink className='nav-link' to={`${routerMatch.path}/payments-manager`}>ניהול תשלומי
                        מערכת</NavLink>
                </div>
                <div className='nav-link d-flex align-items-center '>
                    <UserIcon width={20} height={20} className='text-white'/>
                    <NavLink className='nav-link' exact to={`${routerMatch.path}/users`}>ניהול משתמשים</NavLink>
                </div>
                <div className='nav-link d-flex align-items-center '>
                    <UserIcon width={20} height={20} className='text-white'/>
                    <NavLink className='nav-link' exact to={`${routerMatch.path}/nfc-devices`}>ניהול כרטיסים</NavLink>
                </div>
            </>
        )
    }


    return (
        <Navbar bg="primary" expand="lg">
            <Container fluid>
                <Navbar.Brand href="/admin">
                    <span className='fw-bold text-white'>ניהול מערכת</span>
                </Navbar.Brand>
                <Navbar.Toggle className='bg-white' aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse>
                    <Nav className="ml-auto">
                        {renderNavLink()}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
})
