import {IApi} from "@api";
import {makeAutoObservable} from "mobx";

export interface IPaymentMethodDTO {
    id: string;
    amount: string;
    type: string;
    name: string;
    enabled: boolean;
    rivhitItemId: number;
}

export class PaymentMethodStore {
    public id: string;
    public amount: string;
    public type: string;
    public name: string;
    public enabled: boolean;
    public rivhitItemId: number;

    constructor(private api: IApi, paymentMethodDTO: IPaymentMethodDTO) {
        this.init(paymentMethodDTO)
        makeAutoObservable(this)
    }

    public init(paymentMethodDTO: IPaymentMethodDTO) {
        this.rivhitItemId = paymentMethodDTO.rivhitItemId
        this.id = paymentMethodDTO.id;
        this.name = paymentMethodDTO.name;
        this.type = paymentMethodDTO.type;
        this.amount = paymentMethodDTO.amount;
        this.enabled = paymentMethodDTO.enabled;
    }
    public async update(paymentMethod:Partial<IPaymentMethodDTO>) {
        const paymentMethodDTO = await this.api.updatePaymentMethod(this.id,paymentMethod)
        this.init(paymentMethodDTO)
    }

}
