import React, {useEffect} from 'react'
import {observer} from "mobx-react";
import {DojoStore, UserStore} from "@store";
import {MKTable} from "@foundation-ui";
import {Button} from "react-bootstrap";

interface IProps {
    dojo: DojoStore
    openUserModal?: (user: UserStore) => void
}

export const DojoUsersTable: React.FC<IProps> = observer(({dojo, openUserModal}) => {

    const columns = [
        {
            dataField: 'id',
            text: '#',
            formatter:(col:any,row:UserStore,rowIndex:number)=><div>{rowIndex+1}</div>,
            csvExport: false,
            editable:false,

        },
        {
            dataField: 'firstName',
            text: 'שם',
            editable:false,
        },
        {
            dataField: 'lastName',
            text: 'שם משפחה',
            editable:false,
        },
        {
            dataField: 'karateDetails.rank.name',
            text: 'דרגה',
            editable:false,
        },
        // {
        //     dataField: 'enabled',
        //     text: "פעיל",
        //     csvExport: false,
        //     editable:false,
        //     formatter: (cell: any, row: UserStore) =>
        //         <Form.Check
        //             onChange={(e) => {
        //                 row.setUserEnabledStatus(e.target.checked)
        //             }}
        //             id={row.id}
        //             checked={row.enabled}
        //             type="switch"
        //         />
        // },
        {
            dataField: 'moreDetails',
            text: "מידע נוסף",
            csvExport: false,
            editable:false,
            formatter: (cell: any, row: UserStore) => <Button className='text-white' onClick={() => openUserModal(row)}>מידע
                נוסף</Button>
        },
    ]
    useEffect(() => {
        dojo.getUsers()
    }, [dojo])

    return (
        <MKTable<UserStore> title={dojo.name} keyField='id' data={dojo.users} columns={columns}/>
    )
})
