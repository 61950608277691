import React, { ReactElement } from 'react';
import { useField, useFormikContext } from 'formik';
import { Form } from '@foundation';
import { MKFormErrorMsg } from '@foundation-form';

interface IProps {
    name: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    readOnly?: boolean;
    type?: string;
    validate?: (value: any) => string | Promise<any>;
    children: (props: { value: any, disabled: boolean, name: string, onChange: (value: any) => void }) => ReactElement
}

export const MKCustomFormField: React.FC<IProps> = ({ validate, type = 'text', children, readOnly, disabled, placeholder, label, name }) => {
    const fieldArray = useField({ name, type, validate });
    const field = fieldArray[0];
    const helpers = fieldArray[2]

    const formik = useFormikContext();

    return (
        <Form.Group className='mb-3 text-right'>
            {label && <Form.Label >{label}</Form.Label>}
            {children({ value: field.value, disabled: disabled || formik.isSubmitting, name, onChange: (value: any) => helpers.setValue(value) })}
            <MKFormErrorMsg name={field.name} />
        </Form.Group>
    )
}
