import React, {useEffect} from 'react'
import {observer} from "mobx-react";
import {useAuthStore, UserStore} from "@store";
import {DojoUsersTable, UserModal} from "@components";
import {useMKModal} from "@foundation-ui";


export const DojoAdminPage: React.FC = observer(() => {

        const authStore = useAuthStore()
        const {isOpen, modalData, openModal, closeModal} = useMKModal<UserStore>()
        useEffect(() => {
            (async () => {
                await authStore.user.getDojoAdminMembers()
            })()

        }, [authStore.user])

        return (

            <div className='overflow-auto  d-flex'>
                {authStore.user.dojos.map((dojo) => {
                    return <DojoUsersTable openUserModal={openModal} key={dojo.id} dojo={dojo} />
                })}
                <UserModal open={isOpen} onClose={closeModal} user={modalData}/>
            </div>


        )
    }
)
