import firebase from "firebase/app";
import {IApi} from "@api";
import {UserStore} from "./UserStore";
import {makeAutoObservable, runInAction} from "mobx";

export type IPaymentType = 'check' | 'paypal' | 'direct-debit' | 'cash'

export interface IPaypalDetails {
    orderId: string;
    payerId: string;
}

export interface IPaymentDTO {
    id: string;
    date: {
        seconds: number;
    };
    type: IPaymentType;
    paypalDetails: IPaypalDetails
    paymentMethodName: string;
    paymentMethodType: string;
    amount: string
    user: firebase.firestore.DocumentReference;
    rivhitDocLink: string
    firstName: string;
    lastName: string;
}

export class PaymentStore {
    id: string
    _date: {
        seconds: number;
    };
    paypalDetails: IPaypalDetails
    paymentMethodName: string;
    paymentMethodType: string;
    amount: string;
    userRef: firebase.firestore.DocumentReference;
    user: UserStore;
    rivhitDocLink: string;
    firstName: string;
    lastName: string;
    type: IPaymentType


    constructor(private api: IApi) {
        makeAutoObservable(this)
    }

    public get year(): number {
        return new Date(this._date.seconds * 1000).getFullYear()
    }

    public get date(): string | null {
        if (this._date) {

            return new Date(this._date.seconds * 1000).toLocaleDateString('he')
        }
        return null
    }

    public get time(): string | null {
        if (this._date) {

            return new Date(this._date.seconds * 1000).toLocaleTimeString('he')

        }
        return null
    }

    public get fullName(): string {
        return this.firstName + ' ' + this.lastName
    }


    public init(paymentDTO: IPaymentDTO): this {

        this.id = paymentDTO.id;
        this._date = paymentDTO.date;
        this.paypalDetails = paymentDTO.paypalDetails;
        this.paymentMethodName = paymentDTO.paymentMethodName;
        this.paymentMethodType = paymentDTO.paymentMethodType;
        this.amount = paymentDTO.amount;
        this.userRef = paymentDTO.user;
        this.rivhitDocLink = paymentDTO.rivhitDocLink
        this.firstName = paymentDTO.firstName;
        this.lastName = paymentDTO.lastName;
        this.type = paymentDTO.type
        return this

    }

    public async getUserDetails() {

        await this.api.getUserDetails(this.userRef.id, (userDTO) => {
            runInAction(() => {
                this.user = new UserStore(this.api).init(userDTO)

            })
        })
    }


}
