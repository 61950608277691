import React from 'react';
import {useFormikContext} from 'formik';
import {Alert} from "@foundation";



export const MKFormErrorGlobalMsg: React.FC = () => {
    const {errors} = useFormikContext<{globalError:string}>()
    return (
        errors.globalError as string ? <Alert className='mt-3 text-right' variant='danger'>
                {errors.globalError}
            </Alert>
            : null
    )
}
