import React, {useCallback, useEffect, useReducer} from 'react'
import {observer} from "mobx-react";
import {UserStore} from "@store";
import {MKModal} from "@foundation-ui";
import {Form, Button, ProgressBar} from '@foundation';

interface IProps {
    user: UserStore;
    open: boolean
    onClose: () => void;
}

const initialState = {dataUri: '', progress: 0, isUploading: false};

export const UploadUserProfilePicModal: React.FC<IProps> = observer(({user, open, onClose}) => {

    function reducer(state: any, action: any) {
        switch (action.type) {
            case 'set_file_uri':
                return {...state, dataUri: action.payload.dataUri};
            case 'start_upload':
                return {...state, isUploading: true};
            case 'end_upload':
                return {...state, isUploading: false};
            case 'set_progress':
                return {...state, progress: action.payload.progress};
            default:
                return {...state}
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    const onChange = (e: React.ChangeEvent<any>) => {
        const file = e.target.files[0];
        if (!file) {
            dispatch({type: 'set_file_uri', payload: {dataUri: ''}})
            return;
        }
        const reader = new FileReader();
        reader.onload = (event) => {
            dispatch({type: 'set_file_uri', payload: {dataUri: event.target.result as string}})
        };
        reader.readAsDataURL(file);

    }

    const onUploadPic = () => {
        dispatch({type: 'start_upload'})
        user.uploadProfilePic(state.dataUri, (progress) => {
            dispatch({type: 'set_progress', payload: {progress}})
        })
        dispatch({type: 'end_upload'})
    }

    const handleOnClose = useCallback(() => {
        dispatch({type: 'set_file_uri', payload: {dataUri: ''}})
        onClose()
    },[onClose])

    useEffect(() => {
        if (state.progress === 100) {
            handleOnClose()
        }
    }, [handleOnClose,state.progress])

    return (
        <MKModal
            title='העלאת תמונת פרופיל'
            open={open}
            onClose={handleOnClose}
            showCloseButton
            render={() => {
                return (
                    <div className='p-2'>
                        <Form.Group className='text-right'>
                            <Form.Control disabled={state.isUploading} type="file" onChange={onChange} accept="image/png, image/jpeg, image/jpg"/>
                            <Form.Text className="text-muted">
                                png,jpeg,jpg
                            </Form.Text>
                        </Form.Group>
                        {state.dataUri && <div className='mt-2 w-100 d-flex align-items-center flex-column'>
                            <img
                                className='mb-2'
                                width={150}
                                height={150}
                                alt={`${user.fullName}-profile-pic`}
                                src={state.dataUri}
                            />
                            <Button disabled={state.isUploading} onClick={onUploadPic} className='text-white'>
                                העלאת תמונה
                            </Button>
                            {state.isUploading && <div className='w-100 mt-3'>
                                <ProgressBar label={`${state.progress}%`} animated now={state.progress}/>
                            </div>}
                        </div>}
                    </div>
                )
            }}
        />
    )
})
