import React from 'react'
import {observer} from "mobx-react";
import {useAuthStore} from "@store";
import {MKForm, MKFormErrorGlobalMsg, MKFormField, MKFormSubmitButton} from '@foundation-form';
import {NavLink} from '@router';
import {AuthError} from "@api";
import {FormikHelpers} from "formik";
import {Alert} from "@foundation";


interface ILoginInitialValues {
    email: string;
    password: string;
}

export const LoginPage: React.FC = observer(() => {

        const authStore = useAuthStore()
        const initialValues: ILoginInitialValues = {
            email: '',
            password: ''
        }
        const onSubmit = async (values: ILoginInitialValues, formikHelpers: FormikHelpers<any>) => {
            try {
                await authStore.doLogin(values.email, values.password)
            } catch (e) {
                if (e instanceof AuthError) {
                    formikHelpers.setErrors({globalError: e.message})
                }
                else {
                    formikHelpers.setErrors({globalError: 'שגיאה'})
                }
            }

        }

        return (

            <div>
                <MKForm<ILoginInitialValues> initialValues={initialValues} onSubmit={onSubmit}>
                    {() => {
                        return (
                            <>
                                <MKFormField placeholder='כתובת מייל הרשומה במערכת' label='מייל' validate={(value) => {
                                    if (!value) {
                                        return 'שדה חובה'
                                    }
                                }} name='email'/>
                                <MKFormField type='password' placeholder='סיסמה' label={
                                    <div className='d-flex flex-grow-1 align-items-center justify-content-between'>
                                        סיסמה
                                        <NavLink className='text-primary' to='/reset-password'>שחזור סיסמה</NavLink>
                                    </div>
                                } validate={(value) => {
                                    if (!value) {
                                        return 'שדה חובה'
                                    }
                                }} name='password'/>
                                <MKFormSubmitButton text='כניסה'/>
                                <MKFormErrorGlobalMsg/>
                                {authStore.emailNotVerified && <Alert className='mt-3 text-right' variant='info'>
                                    אנא אשר/י הרשמה במייל
                                </Alert>}
                            </>
                        )
                    }}

                </MKForm>
                <hr/>
                <div className='mt-2 text-center'>
                    <NavLink className='text-primary' to='/register'>להרשמה</NavLink>
                </div>
            </div>


        )
    }
)
