import {AuthStore, DojosStore, PaymentMethodsStore, PaymentsStore, RanksStore} from "@store";
import {Api, IApi} from "@api";
import {FirebaseService} from "@services";
import {makeObservable, observable, runInAction} from "mobx";
import {UsersStore} from "./UsersStore";
import {NfcDevicesStore} from "./NfcDevicesStore";

type TConnectionStatus = 'CONNECTING' | 'READY' | 'FETCHING_USER_DETAILS' | 'FETCHING_DATA';

export class RootStore {

    connectionStatus: TConnectionStatus;
    authStore: AuthStore;
    paymentMethodsStore: PaymentMethodsStore;
    paymentsStore: PaymentsStore;
    ranksStore: RanksStore;
    dojosStore: DojosStore;
    nfcDevicesStore: NfcDevicesStore
    api: IApi;
    usersStore: UsersStore

    constructor(private firebaseService: FirebaseService) {
        this.setConnectionStatus('CONNECTING');
        makeObservable(this, {
            connectionStatus: observable
        })

    }

    public async connect(): Promise<void> {
        try {
            await this.firebaseService.connect();
            this.api = Api.getInstance(this.firebaseService)
            this.authStore = new AuthStore(this, this.api)
            this.paymentsStore = new PaymentsStore(this, this.api)
            this.paymentMethodsStore = new PaymentMethodsStore(this, this.api)
            this.ranksStore = new RanksStore(this, this.api)
            this.dojosStore = new DojosStore(this, this.api)
            this.usersStore = new UsersStore(this, this.api)
            this.nfcDevicesStore = new NfcDevicesStore(this, this.api)
            await this.ranksStore.getRanks()
            await this.dojosStore.getDojos()
            this.firebaseService.authChange(async (user) => {
                if (user) {
                    if (user.emailVerified) {
                        this.authStore.setEmailNotVerified = false
                        runInAction(() => {
                            this.setConnectionStatus('FETCHING_USER_DETAILS');
                        })
                        await this.authStore.getUserDetails(user.uid)
                        runInAction(() => {
                            this.setConnectionStatus('FETCHING_DATA');
                        })
                        await this.paymentMethodsStore.getPaymentMethods()
                        this.authStore.setLoggedInStatus = true
                    } else {
                        await this.firebaseService.signOut()
                        this.authStore.setEmailNotVerified = true
                    }
                }
                runInAction(() => {
                    this.setConnectionStatus('READY');
                })
            })
        } catch (e) {
            console.log(e);
            await this.api.doLogout()
            return
        }
    }

    private setConnectionStatus(status: TConnectionStatus) {
        this.connectionStatus = status;
    }


}


