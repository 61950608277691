import React from 'react'
import {observer} from "mobx-react";
import {UserStore} from "@store";
import {Select} from "@foundation";
import {MKForm, MKFormField,MKCustomFormField} from '@foundation-form';
import {MKModal} from "@foundation-ui";
import {Button, Row,Col} from "react-bootstrap";

interface IProps {
    user: UserStore;
    open: boolean
    onClose: () => void;
}

interface IInitialValues {
    id:string;
    firstName:string;
    lastName:string;
    phoneNumber:string;
    gender:string;
    nutrition:string;
    birthDate:string;
    address:{
        city:string
        street:string;
        streetNumber:string;
    }
    enabled:boolean
}

export const EditUserPersonalDetailsModal: React.FC<IProps> = observer(({user, open, onClose}) => {


    const initialValues:IInitialValues = {
        id:user.id,
        firstName: user.firstName,
        lastName:user.lastName,
        phoneNumber:user.phoneNumber,
        gender:user.gender,
        nutrition:user.nutrition,
        birthDate:user.birthDate,
        address:user.address,
        enabled:user.enabled
    }
    const handleSubmit = async (values:IInitialValues)=>{
        await user.updateUserDetails(values)
        onClose()
    }

    return (
        <MKModal
            title='ערוך פרטי משתמש'
            open={open}
            onClose={onClose}
            render={() => {
                return (
                    <MKForm<IInitialValues> onSubmit={handleSubmit} initialValues={initialValues}>
                        {(formik)=>{
                            return (
                                <>
                                    <div className='p-3 '>
                                        <Row >
                                            <Col lg={4} md={4} sm={6}>
                                                <MKFormField label='שם פרטי' name='firstName'/>
                                            </Col>
                                            <Col lg={4} md={4} sm={6}>
                                                <MKFormField label='שם משפחה' name='lastName'/>
                                            </Col>
                                            <Col lg={4} md={4} sm={6}>
                                                <MKFormField label='טלפון' name='phoneNumber'/>
                                            </Col>
                                            <Col lg={4} md={4} sm={6}>
                                                <MKCustomFormField label='מגדר' name='gender'>
                                                    {({onChange,value:defaultValue})=>{
                                                        const options = [{value:'ז',label:'ז'},{value:'נ',label:'נ'}]
                                                        const value = options.find(item=>item.value === defaultValue)
                                                        return (
                                                            <Select value={value} onChange={({value})=>onChange(value)} options={options}/>
                                                        )

                                                    }}
                                                </MKCustomFormField>
                                            </Col>
                                            <Col lg={4} md={4} sm={6}>
                                                <MKCustomFormField label='תזונה' name='nutrition'>
                                                    {({onChange,value:defaultValue})=>{
                                                        const options = [{value:'רגיל',label:'רגיל'},{value:'צמחוני',label:'צמחוני'},{value:'טבעוני',label:'טבעוני'}]
                                                        const value = options.find(item=>item.value === defaultValue)
                                                        return (
                                                            <Select value={value} onChange={({value})=>onChange(value)} options={options}/>
                                                        )

                                                    }}
                                                </MKCustomFormField>
                                            </Col>
                                            <Col lg={4} md={4} sm={6}>
                                                <MKFormField type='date' label='תאריך לידה' name='birthDate'/>
                                            </Col>

                                            <Col lg={4} md={4} sm={6}>
                                                <MKFormField  label='עיר' name='address.city'/>
                                            </Col>
                                            <Col lg={4} md={4} sm={6}>
                                                <MKFormField  label='רחוב' name='address.street'/>
                                            </Col>
                                            <Col lg={4} md={4} sm={6}>
                                                <MKFormField  label='מספר בית' name='address.streetNumber'/>
                                            </Col>
                                        </Row>

                                    </div>
                                    <div className='gap-2 p-3 border-top d-flex align-items-center justify-content-end'>
                                        <Button disabled={!formik.dirty} className='text-white ml-1' type='submit'>שמור</Button>
                                        {formik.dirty && <Button className='text-white ml-1' onClick={()=>formik.resetForm()}>איפוס</Button>}
                                        <Button variant='danger' className='text-white' onClick={onClose}>סגור</Button>
                                    </div>
                                </>
                            )
                        }}
                    </MKForm>
                )
            }}
        />

    )
})
