import React from 'react'
import {Container} from '@foundation'
import {observer} from "mobx-react";
import {UserDetails} from "@components";
import {useAuthStore} from "@store";
export const UserPage: React.FC = observer(() => {
    const authStore = useAuthStore()
    return (
        <div className='overflow-auto'>
            <Container className='py-4'>
                <UserDetails user={authStore.user}/>
            </Container>
        </div>

    )
})
