import React from "react";
import {observer} from "mobx-react";
import {Route, Switch} from "@router";
import {
    AdminPage,
    AdminPaymentsPage,
    AdminUsersPage,
    AdminPaymentsManagerPage, AdminNfcDevicesPage,
} from "@pages";
import {MKAdminNavbar} from "@foundation-ui";

export const MKAdminRouter: React.FC = observer(() => {
    return (
        <div className='d-flex flex-column overflow-hidden'>
            <MKAdminNavbar/>
            <Switch>
                <Route exact path="/admin" component={AdminPage}/>
                <Route exact path="/admin/payments" component={AdminPaymentsPage}/>
                <Route
                    exact
                    path="/admin/payments-manager/:id?"
                    component={AdminPaymentsManagerPage}
                />
                <Route
                    exact
                    path="/admin/nfc-devices/"
                    component={AdminNfcDevicesPage}
                />
                <Route exact path="/admin/users" component={AdminUsersPage}/>
            </Switch>
        </div>
    );
});
