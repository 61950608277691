import React, {ReactElement} from 'react';
import {Formik, Form, FormikHelpers, FormikProps} from 'formik';

interface IProps<T> {
    initialValues: T
    onSubmit: (values: T, formikHelpers: FormikHelpers<any>) => void
    children: (formik: FormikProps<T>) => ReactElement
    id?: string;
}

export const MKForm = <T extends any>({children, id, initialValues, onSubmit}: IProps<T>): ReactElement => {
    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {(props: FormikProps<T>) =>
                <Form id={id}>
                    {children(props)}
                </Form>
            }
        </Formik>
    )
}




