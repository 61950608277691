import React from 'react';
import {PayPalButtons, usePayPalScriptReducer} from "@paypal/react-paypal-js";
import {Spinner} from '@foundation';

interface IProps {
    amount: string
    onPaymentSuccess?: (paymentId: string, payerName: string) => void
    onError: () => void
    onCanceled: () => void;
}

export const PaypalButton: React.FC<IProps> = ({onPaymentSuccess, onCanceled, onError, amount}) => {

    const [{isPending}] = usePayPalScriptReducer();

    return (
        <>
            {isPending && <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>}
            <PayPalButtons
                style={{
                    color: 'blue',
                    shape: 'pill'
                }}
                forceReRender={[amount]}
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                currency_code: 'ILS',
                                value: amount
                            }
                        }]
                    }).then((orderId) => orderId)
                }}
                onCancel={onCanceled}
                onError={onError}
                onApprove={(data, actions) => {
                    return actions.order.capture().then((details) => {
                        onPaymentSuccess(details.id, details.payer.name.given_name + ' ' + details.payer.name.surname)
                    })
                }}/>
        </>


    )

}
