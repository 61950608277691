import React from 'react'
import {observer} from "mobx-react";
import {useNfcDevicesStore} from "@store";
import {Card} from "react-bootstrap";
import {Badge, Form,Button} from "@foundation";

export const AdminNfcDevices: React.FC = observer(() => {
    const nfcDevicesStore = useNfcDevicesStore()
    return <>{nfcDevicesStore.nfcDevices.map((nfcDevice,index) => <Card key={nfcDevice.id}>
        <Card.Body>
            <Card.Title>קורא כרטיסים {index+1}#             <Badge className='mb-3' bg={nfcDevice.deviceConnected ? 'success' : 'danger'}>{nfcDevice.deviceConnected ? ' מחובר' : ' לא מחובר'}</Badge>
            </Card.Title>

            <Badge className='mx-1 mb-3'
                   bg={nfcDevice.cardConnected ? 'success' : 'danger'}>{nfcDevice.cardConnected ? ` זוהה כרטיס - ${nfcDevice.cardData}` : 'לא זוהה כרטיס'}</Badge>

            <Form.Group className='mb-3'>
                <Form.Label>
                    כתיבת מידע לכרטיס
                </Form.Label>
                <Form.Control disabled={!nfcDevice.cardConnected} placeholder="כתיבת מידע לכרטיס (ת.ז)"/>
            </Form.Group>
            <Button disabled={!nfcDevice.cardConnected} type="submit" size='sm'>רשום לכרטיס</Button>
        </Card.Body>
    </Card>)}</>
})
