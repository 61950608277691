import React, { useMemo } from 'react'
import { observer } from "mobx-react";
import { DojoStore, useDojosStore } from "@store";
import { Form, Select } from "@foundation";

interface IProps {
    name?: string
    onChange: (value: { name: string, id: string }) => void
    defaultValue?: DojoStore;
    placeholder?: string;
    label?: string;
}

export const DojosSelector: React.FC<IProps> = observer(({
    name,
    label,
    placeholder = "דוג'ו",
    onChange,
    defaultValue
}) => {
    const dojosStore = useDojosStore()
    const value = useMemo(() => {
        if (defaultValue) {
            return dojosStore.dojos.find(item => item.id === defaultValue.id)
        }
        return null
    }, [dojosStore.dojos, defaultValue])

    return (
        <Form.Group className='text-right mb-0'>
            {label && <Form.Label>{label}</Form.Label>}
            <Select<DojoStore>
                value={value}
                placeholder={placeholder}
                isRtl
                isSearchable
                name={name}
                options={dojosStore.dojos}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                onChange={(selectedItem) => selectedItem && onChange({ name: selectedItem.name, id: selectedItem.id })}
            />
        </Form.Group>
    )
})
