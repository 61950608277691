import React from 'react'
import { observer } from "mobx-react";
import { MKCustomFormField } from "@foundation-form";
import { DojosSelector } from "@components";

export const DojosSelectorFromField: React.FC = observer(() => {
    return (
        <MKCustomFormField validate={(value) => {
            if (!value.id) {
                return 'שדה חובה'
            }
        }} name='karateDetails.dojo' label='דוג׳ו'>
            {({ value, onChange, name }) => <DojosSelector onChange={onChange} name={name} defaultValue={value} />}
        </MKCustomFormField>
    )
})
