import React, {useCallback} from 'react'
import {observer} from "mobx-react";
import {PaymentMethodStore, usePaymentsStore} from "@store";
import {Alert, Card} from "@foundation";
import {MKModal} from "@foundation-ui";
import {PaypalButton} from "@foundation-buttons";

interface IProps {
    open: boolean
    onClose: () => void;
    paymentMethod: PaymentMethodStore
}

export const PaymentModal: React.FC<IProps> = observer(({paymentMethod, open, onClose}) => {

    const paymentsStore = usePaymentsStore()
    const handlePaypalOnSuccess = useCallback(async (orderId,payerId) => {
        setIsCanceled(false)
        setError(false)
        await paymentsStore.createPaypalPayment(paymentMethod.id, {orderId,payerId})
        setSuccess(true)
    }, [paymentsStore, paymentMethod?.id])
    const [isCanceled, setIsCanceled] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const handleOnClose = React.useCallback(() => {
        setError(false)
        setIsCanceled(false)
        setSuccess(false)
        onClose()
    }, [onClose])
    const render = React.useCallback(() => {
        return (
            <Card>
                <Card.Body className='text-center'>
                    <Card.Title>
                        {paymentMethod?.name}
                    </Card.Title>
                    <hr/>

                    <h5 className='fw-bold m-3 '>{paymentMethod?.amount} שח</h5>
                    {!success && <PaypalButton
                        amount={paymentMethod?.amount}
                        onPaymentSuccess={handlePaypalOnSuccess}
                        onError={() => setError(true)}
                        onCanceled={() => setIsCanceled(true)}
                    />}
                    {isCanceled && <Alert variant='info'>תשלום בוטל</Alert>}
                    {error && <Alert variant='danger'>שגיאה : לא בוצע חיוב</Alert>}
                    {success && <Alert variant='success'>חיוב בוצע בהצלחה</Alert>}
                </Card.Body>
            </Card>
        )
    }, [isCanceled, error, success, paymentMethod, handlePaypalOnSuccess])
    return (
        <MKModal
            size='sm'
            title='תשלום'
            open={open}
            onClose={handleOnClose}
            showCloseButton
            render={render}
        />

    )
})
