import {makeAutoObservable, runInAction} from "mobx";
import {DojoStore, IDojoDTO, PaymentStore} from "@store";
import {IApi} from "@api";
import firebase from "firebase/app";

interface IKarateDetails {
    dojo: {
        id: string;
        name: string
    }
    rank: {
        id: string;
        name: string
    }
    joiningYear: string
    rankYear: string
    specialTrainings: string
    dojoAdmin: firebase.firestore.DocumentReference[]
}

export interface IUserDTO {
    enabled?: boolean
    id?: string;
    address?: {
        city: string,
        street: string,
        streetNumber: string,
    }
    birthDate?: string
    email?: string
    idNumber?: string
    firstName?: string
    lastName?: string
    phoneNumber?: string
    roles?: {
        isAdmin: boolean
        isDojoAdmin: boolean
        paymentsAdmin: boolean
        specialTrainingsAdmin: boolean
        usersAdmin: boolean
    }
    gender?: string
    nutrition?: string
    password?: string;
    profilePicUrl?: string;
    membershipPayments?: { [key: number]: boolean }
    karateDetails?: Partial<IKarateDetails>
}

export class UserStore {
    dojos: DojoStore[] = []
    enabled: boolean;
    id: string;
    address: {
        city: string,
        street: string,
        streetNumber: string,
    };
    birthDate: string;
    email: string;
    idNumber: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    roles: {
        isAdmin: boolean,
        isDojoAdmin: boolean,
        paymentsAdmin: boolean,
        specialTrainingsAdmin: boolean,
        usersAdmin: boolean,
    }
    gender: string;
    nutrition: string;
    profilePicUrl?: string;
    membershipPayments: { [key: number]: boolean }
    karateDetails: Partial<IKarateDetails>
    payments: PaymentStore[] = []

    constructor(private api: IApi) {
        makeAutoObservable(this)
    }

    public get isAdmin(): boolean {
        return this.roles && this.roles.isAdmin
    }

    public get isDojoAdmin(): boolean {
        return this.roles && this.roles.isDojoAdmin
    }

    public get age(): number {
        const ageDifMs = Date.now() - new Date(this.birthDate).getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    public get seniorityRank(): number {
        const ageDifMs = Date.now() - new Date(this.karateDetails?.rankYear).getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    public get fullName(): string {
        return this.firstName + ' ' + this.lastName
    }

    public getPayments(): void {
        if (!this.payments.length) {
            this.api.getUserPayments(this.id,
                (paymentDTO) => {
                    const payment = new PaymentStore(this.api).init(paymentDTO)
                    runInAction(() => {
                        this.payments = [...this.payments,payment]
                    })
                },
                (paymentsDTOS) => {
                    runInAction(() => {
                        this.payments = paymentsDTOS.map(paymentDTO => new PaymentStore(this.api).init(paymentDTO))
                    })
                })

        }
    }

    public async uploadProfilePic(file: string, onProgress: (progress: number) => void): Promise<void> {

        const url = await this.api.uploadUserProfilePic(this.fullName, file, onProgress)
        await this.updateUserDetails({id: this.id, profilePicUrl: url})

    }

    public get membershipStatus(): boolean {
        const currentYear = new Date().getFullYear()
        return this.membershipPayments && !!this.membershipPayments[currentYear]
    }

    public setUserEnabledStatus(enabled: boolean) {
        this.enabled = enabled
        this.updateUserDetails({id: this.id, enabled: this.enabled})
    }

    public async updateUserDetails(user: Partial<IUserDTO>) {
        await this.api.updateUserDetails(user)
    }

    public async getDojoAdminMembers(): Promise<{ dojoName: string, members: UserStore[] }[]> {
        if (this.karateDetails.dojoAdmin) {
            const dojosPromises = this.karateDetails.dojoAdmin.map(async (dojoRef) => {
                return await dojoRef.get()
            })
            const dojos = await Promise.all(dojosPromises)
            runInAction(() => {
                this.dojos = dojos.map((dojo) => {
                    return new DojoStore({id: dojo.id, ...dojo.data()} as IDojoDTO, this.api)
                })
            })

        }
        return null

    }

    public init(userDTO: IUserDTO): this {
        this.id = userDTO.id
        this.address = userDTO.address
        this.birthDate = userDTO.birthDate
        this.email = userDTO.email
        this.idNumber = userDTO.idNumber
        this.firstName = userDTO.firstName
        this.lastName = userDTO.lastName
        this.phoneNumber = userDTO.phoneNumber
        this.roles = userDTO.roles
        this.gender = userDTO.gender
        this.nutrition = userDTO.nutrition
        this.karateDetails = userDTO.karateDetails
        this.membershipPayments = userDTO.membershipPayments;
        this.profilePicUrl = userDTO.profilePicUrl
        this.enabled = userDTO.enabled
        return this
    }

}
