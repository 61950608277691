import React from 'react'
import {observer} from "mobx-react";
import {IPaymentMethodDTO, PaymentMethodStore} from "@store";
import {MKForm, MKFormField} from '@foundation-form';
import {MKModal} from "@foundation-ui";
import {Button, Col, Row} from "react-bootstrap";

interface IProps {
    paymentMethod: PaymentMethodStore;
    open: boolean
    onClose: () => void;
}

export const EditPaymentMethodDetailsModal: React.FC<IProps> = observer(({paymentMethod, open, onClose}) => {

    const initialValues: Partial<IPaymentMethodDTO> = {
        enabled: paymentMethod?.enabled ?? false,
        name: paymentMethod?.name ?? '',
        amount: paymentMethod?.amount ?? null
    }
    const handleSubmit = async (values: Partial<IPaymentMethodDTO>) => {
        await paymentMethod.update(values)
        onClose()
    }

    return (
        <MKModal
            title='ערוך פרטי תשלום'
            open={open}
            onClose={onClose}
            render={() => {
                return (
                    <MKForm<Partial<IPaymentMethodDTO>> onSubmit={handleSubmit} initialValues={initialValues}>
                        {(formik) => {
                            return (
                                <div>
                                    <div className='p-3'>
                                        <Row>
                                            <Col>
                                                <MKFormField label='שם' name='name'/>
                                            </Col>
                                            <Col>
                                                <MKFormField label='מחיר' name='amount'/>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className='gap-2 p-3 border-top d-flex align-items-center justify-content-end'>
                                        <Button disabled={!formik.dirty} className='text-white ml-1'
                                                type='submit'>שמור</Button>
                                        {formik.dirty && <Button className='text-white ml-1'
                                                                 onClick={() => formik.resetForm()}>איפוס</Button>}
                                        <Button variant='danger' className='text-white' onClick={onClose}>סגור</Button>
                                    </div>
                                </div>
                            )
                        }}
                    </MKForm>
                )
            }}
        />

    )
})
