import React from 'react';
import {observer} from "mobx-react";
import {Nav, Navbar} from "@foundation";
import {NavLink} from "@router";
import {AdminIcon, DojoAdminIcon, LogoutIcon, PaymentIcon, UserIcon, ShotokanLogoWhite} from '@assets'
import {useAuthStore} from "@store";
import {Container} from "react-bootstrap";

export const MKNavbar: React.FC = observer(() => {

    const authStore = useAuthStore()


    const renderLogoutNavLink = () => {
        if (authStore.loggedIn) {
            return (
                <div className='nav-link d-flex align-items-center '>
                    <LogoutIcon width={20} height={20} className='text-white'/>
                    <Nav.Link className='text-right' onClick={() => authStore.doLogout()}>יציאה</Nav.Link>
                </div>
            )

        }
        return null
    }
    const renderNavLink = () => {
        return (
            <>
                <div className='nav-link d-flex align-items-center '>
                    <UserIcon width={20} height={20} className='text-white'/>
                    <NavLink className='nav-link' exact to='/user'>פרופיל</NavLink>
                </div>
                <div className='nav-link d-flex align-items-center '>
                    <PaymentIcon width={20} height={20} className='text-white'/>
                    <NavLink className='nav-link' exact to='/payments'>תשלומים</NavLink>
                </div>
                {renderDojoAdminNavLinks()}
                {renderAdminNavLinks()}
            </>
        )
    }
    const renderAdminNavLinks = () => {
        if (authStore.user.isAdmin) {
            return (
                <div className='nav-link d-flex align-items-center'>
                    <AdminIcon width={20} height={20} className='text-white'/>
                    <NavLink className='nav-link' to='/admin'>ניהול מערכת</NavLink>
                </div>
            )
        }
        return null
    }
    const renderDojoAdminNavLinks = () => {
        if (authStore.user.isDojoAdmin) {
            return (
                <div className='nav-link d-flex align-items-center'>
                    <DojoAdminIcon width={20} height={20} className='text-white'/>
                    <NavLink className='nav-link' to='/dojo-admin'>ניהול דוג'ו</NavLink>
                </div>
            )
        }
        return null
    }

    return (
        <Navbar variant='light' bg="secondary" expand="lg">
            <Container fluid >

                <Navbar.Brand className='gap-3 d-flex align-items-center' href="/">
                    <img style={{height: '3rem', width: '3rem'}} alt="shotokan-logo"
                         src={ShotokanLogoWhite}
                    />
                    <span className='fw-bold text-white'>שוטוקאן ישראל</span>
                </Navbar.Brand>
                <Navbar.Toggle className='bg-white' aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse>
                    <Nav className="flex-grow-1">
                        {renderNavLink()}
                    </Nav>
                    <Nav>
                        {renderLogoutNavLink()}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
})
