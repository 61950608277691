import React from 'react'
import {observer} from "mobx-react";
import {Form, Select} from "@foundation";

interface IProps {
    name?: string
    onChange: (value: string) => void
    defaultValue: string;
}

export const PaymentMethodTypeSelector: React.FC<IProps> = observer(({name, onChange, defaultValue}) => {

    const paymentMethodTypes = [{value: 'general', label: 'כללי'}, {
        value: 'memberShip',
        label: 'דמי חבר'
    }, {value: 'specialTraining', label: 'אימון מיוחד'}]
    const value = paymentMethodTypes.find(item => item.value === defaultValue)
    return (
        <Form.Group className='text-right'>
            <Form.Label className='d-flex'>סוג תשלום</Form.Label>
            <Select
                value={value}
                placeholder='סוג תשלום'
                isRtl
                isSearchable
                name={name}
                options={paymentMethodTypes}
                onChange={(selectedItem) => onChange(selectedItem.value)}
            />
        </Form.Group>
    )
})
