import {DojoStore, RootStore} from "@store";
import {IApi} from "@api";
import {makeAutoObservable} from "mobx";


export class DojosStore {


    dojos: DojoStore[]

    constructor(private rootStore: RootStore, private api: IApi) {
        this.dojos = []
        makeAutoObservable(this)
    }

    public async getDojos(): Promise<void> {
        return this.api.getDojos((dojo) => {
            this.dojos.push(new DojoStore(dojo, this.api))
        })
    }

    public getDojoById(id: string): DojoStore {
        return this.dojos.find(dojo => dojo.id === id)
    }
}
