import React from 'react';
import {observer} from "mobx-react";
import {Switch, Route, MKAdminRouter,Redirect} from '@router'
import {DojoAdminPage, PaymentsPage, UserPage} from "@pages";
import {MKNavbar} from "@foundation-ui";

export const MKPrivateRouter: React.FC = observer(() => {
    return (
        <div style={{overflow:'hidden',flex:1,display:'grid',gridTemplateRows:'auto 1fr'}}>
            <MKNavbar/>
                <Switch>
                    <Route exact path='/user' component={UserPage}/>
                    <Route exact path='/payments' component={PaymentsPage}/>
                    <Route path='/admin' component={MKAdminRouter}/>
                    <Route path='/dojo-admin' component={DojoAdminPage}/>
                    <Redirect to='/user'/>
                </Switch>
        </div>

    )
})


