import React from 'react'
import {observer} from "mobx-react";
import {PaymentMethodStore} from "@store";
import {Button, Card} from "react-bootstrap";

interface IProps {
    paymentMethod: PaymentMethodStore
    disabledText?: string | null
    setLoading: (status: boolean) => void
    onClick: (paymentMethod: PaymentMethodStore) => void
}

export const PaymentCard: React.FC<IProps> = observer(({onClick, paymentMethod}) => {


    return (
        <Card style={{height: 200}}>
            <Card.Body className='text-center d-flex flex-column align-items-center justify-content-between'>
                    <Card.Title className='fw-normal '>
                        {paymentMethod.name}
                    </Card.Title>
                    <hr className='align-self-stretch'/>
                    <h5 className='fw-bold '>{paymentMethod.amount} שח</h5>
                    <Button className='w-100 text-white' onClick={() => onClick(paymentMethod)}>לתשלום</Button>
            </Card.Body>
        </Card>

    )
})
