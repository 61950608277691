import React from 'react';
import {observer} from "mobx-react";
import {MKAuthRouter, MKPrivateRouter, Router} from '@router'
import {useAuthStore} from "@store";

export const MKRouter: React.FC = observer(() => {
    const authStore = useAuthStore()
    const {loggedIn} = authStore
    return (
        <Router>
            {!loggedIn ? <MKAuthRouter/> : <MKPrivateRouter/>}
        </Router>
    )
})


