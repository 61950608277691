import React from 'react'
import {observer} from "mobx-react";
import {IUserDTO, useAuthStore} from '@store';
import {
    MKForm,
    MKFormField,
    MKCustomFormField,
    MKFormErrorGlobalMsg,
    MKFormSubmitButton,
    idValidation
} from '@foundation-form';
import {Select, Col, Row} from '@foundation';
import {RanksSelectorFromField, DojosSelectorFromField} from '@components';
import {NavLink, useHistory} from '@router';
import {AuthError} from "@api";
import {FormikHelpers} from "formik";

export const RegisterPage: React.FC = observer(() => {

    const authStore = useAuthStore()
    const history = useHistory();
    const initialValues: IUserDTO = {
        email: '',
        password: '',
        idNumber: '',
        firstName: '',
        lastName: '',
        address: {
            city: '',
            street: '',
            streetNumber: ''
        },
        phoneNumber: '',
        gender: '',
        nutrition: '',
        birthDate: '',
        karateDetails: {
            dojo: {
                id: '',
                name: ''
            },
            rank: {
                id: '',
                name: ''
            },
            joiningYear: '',
            rankYear: '',
            specialTrainings: '',

        }
    }

    const onSubmit = async (values: IUserDTO, formikHelpers: FormikHelpers<any>) => {
        try {
            await authStore.doRegister(values)
            history.replace('/login')
        } catch (e) {
            if (e instanceof AuthError) {
                formikHelpers.setErrors({globalError: e.message})
            } else {
                formikHelpers.setErrors({globalError: 'שגיאה'})
            }
        }


    }
    return (
        <div>
            <MKForm<IUserDTO> initialValues={initialValues} onSubmit={onSubmit}>
                {() => <div>
                    <Row>
                        <Col xs={12} sm={6}>
                            <MKFormField placeholder="שם פרטי" label='שם פרטי' validate={(value) => {
                                if (!value) {
                                    return 'שדה חובה'
                                }
                            }} name='firstName'/>
                        </Col>
                        <Col xs={12} sm={6}>
                            <MKFormField placeholder='שם משפחה' label='שם משפחה' validate={(value) => {
                                if (!value) {
                                    return 'שדה חובה'
                                }
                            }} name='lastName'/>
                        </Col>
                        <Col xs={12} sm={6}>
                            <MKFormField label='תעודת זהות' placeholder='תעודת זהות' validate={(value) => {
                                if (!value) {
                                    return 'שדה חובה'
                                } else if (!idValidation(value)) {

                                    return 'תעודת זהות אינה תקינה'

                                }
                            }} name='idNumber'/>
                        </Col>
                        <Col xs={12} sm={6}>
                            <MKFormField type='date' label='תאריך לידה' validate={(value) => {
                                if (!value) {
                                    return 'שדה חובה'
                                }
                            }} name='birthDate'/>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={6}>
                            <MKFormField placeholder='כתובת מייל' label='מייל' validate={(value) => {
                                if (!value) {
                                    return 'שדה חובה'
                                }
                            }} name='email'/>
                        </Col>
                        <Col xs={12} sm={6}>
                            <MKFormField helperText='סיסמה חייבת להכיל 6 תווים ומעלה' type='password' placeholder='סיסמה' label='סיסמה' validate={(value) => {
                                if (!value) {
                                    return 'שדה חובה'
                                }
                            }} name='password'/>
                        </Col>
                    </Row>

                    <Row>

                        <Col  xs={12} sm={4}>
                            <MKFormField placeholder='טלפון' label='טלפון' validate={(value) => {
                                if (!value) {
                                    return 'שדה חובה'
                                }
                            }} name='phoneNumber'/>
                        </Col>
                        <Col  xs={6} sm={4}>

                            <MKCustomFormField validate={(value) => {
                                if (!value) {
                                    return 'שדה חובה'
                                }
                            }} label='מגדר' name='gender'>
                                {({onChange, value: defaultValue}) => {
                                    const options = [{value: 'ז', label: 'ז'}, {value: 'נ', label: 'נ'}]
                                    const value = options.find(item => item.value === defaultValue)
                                    return (
                                        <Select placeholder='מגדר' value={value} onChange={({value}) => onChange(value)}
                                                options={options}/>
                                    )

                                }}
                            </MKCustomFormField>
                        </Col>
                        <Col  xs={6} sm={4}>

                            <MKCustomFormField validate={(value) => {
                                if (!value) {
                                    return 'שדה חובה'
                                }
                            }} label='תזונה' name='nutrition'>
                                {({onChange, value: defaultValue}) => {
                                    const options = [{value: 'רגיל', label: 'רגיל'}, {
                                        value: 'צמחוני',
                                        label: 'צמחוני'
                                    }, {value: 'טבעוני', label: 'טבעוני'}]
                                    const value = options.find(item => item.value === defaultValue)
                                    return (
                                        <Select placeholder='תזונה' value={value}
                                                onChange={({value}) => onChange(value)} options={options}/>
                                    )

                                }}
                            </MKCustomFormField>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6} sm={4}>
                            <MKFormField placeholder='עיר' label='עיר' name='address.city'/>
                        </Col>
                        <Col xs={6} sm={4}>
                            <MKFormField placeholder='רחוב' label='רחוב' name='address.street'/>
                        </Col>
                        <Col xs={12} sm={4}>
                            <MKFormField placeholder='מספר בית' label='מספר בית' name='address.streetNumber'/>
                        </Col>
                    </Row>


                    <Row>
                        <Col xs={12} sm={6}>
                            <RanksSelectorFromField/>
                        </Col>
                        <Col xs={12} sm={6}>
                            <DojosSelectorFromField/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={4}>
                            <MKFormField placeholder='שנת הצטרפות' label='שנת הצטרפות' validate={(value) => {
                                if (!value) {
                                    return 'שדה חובה'
                                }
                            }} name='karateDetails.joiningYear'/>
                        </Col>
                        <Col xs={12} sm={4}>
                            <MKFormField placeholder='שנת קבלת דרגה' label='שנת קבלת דרגה' validate={(value) => {
                                if (!value) {
                                    return 'שדה חובה'
                                }
                            }} name='karateDetails.rankYear'/>
                        </Col>
                        <Col xs={12} sm={4}>
                            <MKFormField placeholder='אימונים מיוחדים' label='אימונים מיוחדים'
                                         name='karateDetails.specialTrainings'/>
                        </Col>
                    </Row>

                    <MKFormSubmitButton text='הרשמה'/>
                    <MKFormErrorGlobalMsg/>

                </div>}

            </MKForm>
            <hr/>
            <div className='mt-2 text-center'>
                <NavLink className='text-primary' to='/login'>חזרה לכניסה</NavLink>
            </div>
        </div>
    )
})
