import React, {useMemo} from 'react'
import {observer} from "mobx-react";
import {Form, Select} from "@foundation";
import {IPaymentType} from "@store";

interface IProps {
    name?: string
    onChange: (value: IPaymentType) => void
    defaultValue?: string;
}

export const PaymentTypeSelector: React.FC<IProps> = observer(({name, onChange, defaultValue}) => {

    const paymentTypes: { value: IPaymentType, label: string }[] = React.useMemo(() => [{
        value: 'paypal',
        label: 'paypal'
    }, {value: 'direct-debit', label: 'הוראת קבע'}, {value: 'cash', label: 'מזומן'}, {
        value: 'check',
        label: "צ'ק"
    }], [])

    const value = useMemo(() => {
        return paymentTypes.find(item => item.value === defaultValue)
    }, [paymentTypes, defaultValue])


    return (
        <Form.Group className='text-right'>
            <Form.Label className='d-flex'>סוג תשלום</Form.Label>
            <Select<{ value: IPaymentType, label: string }>
                value={value}
                placeholder='סוג תשלום'
                isRtl
                isSearchable
                name={name}
                options={paymentTypes}
                onChange={(selectedItem) => onChange(selectedItem.value)}
            />
        </Form.Group>
    )
})
