import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react";
import {DojosSelector, RanksSelector, UserDetails, UserModal, UsersSelector, UsersTable} from "@components";
import {DojoStore, RankStore, useDojosStore, useRanksStore, UserStore, useUsersStore} from "@store";
import {useMKModal} from "@foundation-ui";
import {Button, Row, Col, Stack} from "@foundation";


export const AdminUsersPage: React.FC = observer(() => {

    const ranksStore = useRanksStore()
    const dojosStore = useDojosStore()
    const usersStore = useUsersStore()

    const [rank, setRank] = useState<RankStore>(null)
    const [dojo, setDojo] = useState<DojoStore>(null)
    const [users, setUsers] = useState<UserStore[]>([])
    const [title, setTitle] = useState<string>('')
    const {isOpen, modalData, openModal, closeModal} = useMKModal<UserStore>()

    useEffect(() => {
        if (rank) {
            (async () => {
                await rank.getMembers()
                setUsers(rank.members)
                setTitle(rank.name)
            })()
        }
        if (dojo) {
            (async () => {
                await dojo.getUsers()
                setUsers(dojo.users)
                setTitle(dojo.name)
            })()
        }

    }, [rank, dojo])

    return (
        <>
            <div className='overflow-auto d-flex flex-grow-1 flex-column p-4 text-right'>
                <h3 className='fw-bold'>ניהול משתמשים</h3>
                <Stack className='mb-3' gap={3}>
                    <Row>
                        <Col xs={12}  md={4}>
                            <RanksSelector defaultValue={rank} label='חיפוש לפי דרגה' placeholder='חפש משתמשים לפי דרגה'
                                           onChange={(rank) => {
                                               const selectedRank = ranksStore.getRankById(rank.id)
                                               setRank(selectedRank)
                                               setDojo(null)
                                           }}/>
                        </Col>
                        <Col xs={12}  md={4}>
                            <DojosSelector defaultValue={dojo} label="חיפוש לפי דוג'ו" placeholder="חפש משתמשים לפי דוג'ו"
                                           onChange={(dojo) => {
                                               const selectedDojo = dojosStore.getDojoById(dojo.id)
                                               setDojo(selectedDojo)
                                               setRank(null);
                                           }}/>
                        </Col>
                        <Col xs={12}  md={4}>
                            <UsersSelector label='חיפוש משתמשים' onChange={usersStore.setUser}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}  md={4}>
                            <Button className='w-100 text-white'  onClick={async () => {
                                const users = await usersStore.getUsers()
                                setTitle('כל המשתמשים')
                                setUsers(users)
                            }}>
                                כל המשתמשים
                            </Button>
                        </Col>
                    </Row>
                </Stack>
                {!!users.length && <UsersTable openUserModal={openModal} title={title} users={users}/>}
                {usersStore.user && <UserDetails user={usersStore.user}/>}
            </div>
            <UserModal open={isOpen} onClose={closeModal} user={modalData}/>
        </>

    )
})
