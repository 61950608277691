import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react";
import {UserStore, useUsersStore} from "@store";
import {Form, Select} from "@foundation";

interface IProps {
    name?: string
    onChange: (value: string) => void
    defaultValue?: UserStore;
    placeholder?: string;
    label?: string;
    disabled?: boolean;
}

export const UsersSelector: React.FC<IProps> = observer(({
                                                             name,
                                                             label,
                                                             placeholder = "חיפוש משתמשים",
                                                             onChange,
                                                             defaultValue,
                                                             disabled
                                                         }) => {
    const [searchUserText, setSearchUserText] = useState<string>('')
    const [searchUsers, setSearchUsers] = useState<UserStore[]>([])
    const usersStore = useUsersStore()

    useEffect(() => {
        if (searchUserText.length > 2) {
            (async () => {
                const users = await usersStore.searchUsers(searchUserText)
                setSearchUsers(users)
            })()

        }

    }, [usersStore, searchUserText])


    return (
        <Form.Group className='text-right'>
            {label && <Form.Label>{label}</Form.Label>}
            <Select<UserStore>
                isDisabled={disabled}
                isClearable
                defaultValue={defaultValue}
                placeholder={placeholder}
                isRtl
                isSearchable
                name={name}
                getOptionLabel={(option) => option.fullName}
                getOptionValue={option => option.id}
                onInputChange={setSearchUserText}
                options={searchUsers}
                onChange={(selectedItem) => selectedItem && onChange(selectedItem.id)}
            />
        </Form.Group>
    )
})
