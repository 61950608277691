import React, {ReactElement} from 'react';
import {observer} from "mobx-react";
import {Modal, Button, CloseButton} from "@foundation";

interface IProps {
    open: boolean
    onClose: () => void;
    render: () => ReactElement;
    title: string;
    buttons?: (() => void) | null;
    showCloseButton?: boolean
    size?: 'sm' | 'lg' | 'xl';
}

export const MKModal: React.FC<IProps> = observer(({size, showCloseButton, open, buttons, onClose, render, title}) => {
    return (
        <Modal
            size={size}
            show={open}
            centered
            backdrop='static'
        >
            <Modal.Header className='d-flex align-items-center justify-content-between'>
                <Modal.Title className='fw-bold'>
                    {title}
                </Modal.Title>
                <CloseButton className='m-0' onClick={onClose}/>
            </Modal.Header>
            <Modal.Body className='p-0'>
                {render()}
            </Modal.Body>
            {showCloseButton &&
                <Modal.Footer>
                    <Button variant='danger' className='text-white' onClick={onClose}>סגור</Button>
                </Modal.Footer>}

        </Modal>
    )
})
