import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './theme/css/index.css'
import reportWebVitals from './reportWebVitals';
ReactDOM.render(

    <App />
  ,
  document.getElementById('root')
);
reportWebVitals();
