import React from 'react'
import {observer} from "mobx-react";
import {IPaymentMethodDTO} from "@store";
import {MKForm, MKFormField} from '@foundation-form';
import {MKModal} from "@foundation-ui";
import {Button, Col, Row} from "react-bootstrap";
import {PaymentMethodTypeSelector} from '@components';

interface IProps {
    open: boolean
    onClose: () => void;
    onSubmit: (paymentMethod: Partial<IPaymentMethodDTO>) => void;
}

export const AddPaymentMethodModal: React.FC<IProps> = observer(({open, onSubmit, onClose}) => {

    const initialValues: Partial<IPaymentMethodDTO> = {
        enabled: true,
        name: '',
        amount: null,
        rivhitItemId: 0,
        type: ''
    }
    const handleSubmit = async (values: Partial<IPaymentMethodDTO>) => {
        onSubmit(values)
        onClose()
    }

    return (
        <MKModal
            title='הוספת אמצעי תשלום חדש'
            open={open}
            onClose={onClose}
            render={() => {
                return (
                    <MKForm<Partial<IPaymentMethodDTO>> onSubmit={handleSubmit} initialValues={initialValues}>
                        {(formik) => {
                            return (
                                <div>
                                    <div className='p-3'>
                                        <Row>
                                            <Col xs={12}>
                                                <MKFormField label='שם' name='name'/>
                                            </Col>
                                            <Col xs={12}>
                                                <MKFormField label='מחיר' name='amount'/>
                                            </Col>
                                            <Col xs={12}>
                                                <MKFormField label='מספר פריט ברווחית' name='rivhitItemId'/>
                                            </Col>
                                            <Col xs={12}>
                                                <PaymentMethodTypeSelector
                                                    defaultValue={formik.values.type}
                                                    onChange={(value) => formik.setFieldValue('type', value)}
                                                    name='type'/>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className='gap-2 p-3 border-top d-flex align-items-center justify-content-end'>
                                        <Button disabled={!formik.isValid} className='text-white ml-1'
                                                type='submit'>שמור</Button>

                                        <Button variant='danger' className='text-white' onClick={onClose}>סגור</Button>
                                    </div>
                                </div>
                            )
                        }}
                    </MKForm>
                )
            }}
        />

    )
})
