import {RootStore} from "@store";
import {IApi} from "@api";
import {makeAutoObservable} from "mobx";
import {NfcDeviceStore} from "./NfcDeviceStore";


export class NfcDevicesStore {

    nfcDevices: NfcDeviceStore[]

    constructor(private rootStore: RootStore, private api: IApi) {
        this.nfcDevices = []
        makeAutoObservable(this)
    }

    public async getNfcDevices(): Promise<void> {
        return this.api.getNfcDevices((nfcDeviceDTO) => {
            this.nfcDevices.push(new NfcDeviceStore(nfcDeviceDTO))
        },(nfcDeviceDTO)=>{
            const nfcDevice = this.nfcDevices.find(nfcDevice=>nfcDevice.id === nfcDeviceDTO.id)
            nfcDevice.init(nfcDeviceDTO)
        })
    }


}
