import React from 'react'
import {observer} from "mobx-react";
import {UserStore} from "@store";
import {Badge} from "react-bootstrap";

interface IProps {
    user: UserStore
}

export const UserMemberShipPaymentStatus: React.FC<IProps> = observer(({user}) => {


    const statusText = user.membershipStatus ? 'שולם' : 'לא שולם'
    const text = `דמי חבר : ${statusText} לשנת ${new Date().getFullYear()}`

    return (
        <h5 className='m-0'><Badge bg={user.membershipStatus ? 'success' : 'danger'}>{text}</Badge></h5>

    )
})
