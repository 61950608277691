import React from 'react';
import {observer} from "mobx-react";
import {Switch, Route, Redirect} from '@router'
import {
    LoginPage,
    RegisterPage, ResetPassword,
} from "@pages";
import {Container, Row, Col, Card} from '@foundation';
import {ShotokanLogoBlack} from '@assets';
import {useLocation} from 'react-router';

export const MKAuthRouter: React.FC = observer(() => {

    const location = useLocation();

    const title = React.useMemo(() => {
        if (location.pathname === '/register') {
            return 'הרשמה'
        }
        if (location.pathname === '/login') {
            return 'התחברות'
        }
        if (location.pathname === '/reset-password') {
            return 'איפוס סיסמה'
        }
    }, [location])


    return (
        <Container className='d-flex justify-content-center vh-100'>
            <Row className='d-flex justify-content-center align-items-center w-100'>
                <Col className="p-2" xl={6} lg={8} md={12} sm={12} xs={12}>
                    <Card className="bg-light">
                        <Card.Body>
                            <div className="text-primary text-center">
                                <img width={120} height={100} src={ShotokanLogoBlack} alt="shotokan-logo"/>
                                <h2 className="fw-bold">שוטוקאן ישראל</h2>
                                <h4 className="fw-bold">{title}</h4>
                            </div>
                            <hr/>
                            <Switch>
                                <Route exact path='/login' component={LoginPage}/>
                                <Route exact path='/register' component={RegisterPage}/>
                                <Route exact path='/reset-password' component={ResetPassword}/>
                                <Redirect to='/login'/>
                            </Switch>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    )
})


