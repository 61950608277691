import React from 'react'
import {observer} from "mobx-react";
import {Badge, Button, Card} from "@foundation";
import {PaymentMethodStore} from "@store";

interface IProps {
    paymentMethod: PaymentMethodStore
    openEditModal: (paymentMethod: PaymentMethodStore) => void
}

export const PaymentMethodItem: React.FC<IProps> = observer(({paymentMethod, openEditModal}) => {


    return (
        <Card>
            <Card.Body>
                <Card.Title className='d-flex justify-content-between align-items-center'>
                        {paymentMethod.name}
                    <Button size='sm' onClick={() => openEditModal(paymentMethod)}
                            variant="outline-primary">ערוך</Button>
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">מחיר : {paymentMethod.amount} שח </Card.Subtitle>

                <Card.Text>
                    <Badge bg={paymentMethod.enabled ? 'success' : 'danger'}>{paymentMethod.enabled ? 'פעיל' : 'לא פעיל'}</Badge>
                </Card.Text>

                {paymentMethod.enabled &&
                    <Button size='sm' onClick={() => paymentMethod.update({enabled: false})} variant="outline-danger">בטל
                        תשלום</Button>}
                {!paymentMethod.enabled &&
                    <Button size='sm' onClick={() => paymentMethod.update({enabled: true})} variant="outline-success">הפעל
                        תשלום</Button>}
            </Card.Body>
        </Card>
    )
})
