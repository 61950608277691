import React, { useMemo } from 'react'
import { observer } from "mobx-react";
import { RankStore, useRanksStore } from "@store";
import { Form, Select } from "@foundation";

interface IProps {
    name?: string
    onChange: (value: { name: string, id: string }) => void
    defaultValue?: RankStore;
    placeholder?: string;
    label?: string;
}

export const RanksSelector: React.FC<IProps> = observer(({
    name,
    label,
    placeholder = 'דרגות',
    onChange,
    defaultValue
}) => {
    const ranksStore = useRanksStore()
    const value = useMemo(() => {
        if (defaultValue) {
            return ranksStore.ranks.find(item => item.id === defaultValue.id)
        }
        return null
    }, [ranksStore.ranks, defaultValue])

    return (
        <Form.Group className='text-right mb-0'>
            {label && <Form.Label>{label}</Form.Label>}
            <Select<RankStore>
                value={value}
                placeholder={placeholder}
                isRtl
                isSearchable
                name={name}
                options={ranksStore.ranks}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                onChange={(selectedItem) => selectedItem && onChange({ name: selectedItem.name, id: selectedItem.id })}
            />
        </Form.Group>

    )
})
