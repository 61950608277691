import {RankStore, RootStore} from "@store";
import {IApi} from "@api";
import {makeAutoObservable} from "mobx";


export class RanksStore {


    ranks: RankStore[]

    constructor(private rootStore: RootStore, private api: IApi) {
        this.ranks = []
        makeAutoObservable(this)
    }

    public async getRanks(): Promise<void> {
        return this.api.getRanks((rank) => {
            this.ranks.push(new RankStore(rank, this.api))
        });
    }

    public getRankById(id: string): RankStore {
        return this.ranks.find(rank => rank.id === id)
    }

}
