import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react";
import {usePaymentsStore} from "@store";
import {AdminPaymentsTable} from "@components";

export const AdminPaymentsPage: React.FC = observer(() => {

        const paymentsStore = usePaymentsStore()

        const [year, selectYear] = useState(new Date().getFullYear().toString())
        const [paymentMethod, selectPaymentMethod] = useState('all')


        useEffect(() => {
            paymentsStore.getPayments(year, paymentMethod)
        }, [paymentsStore, year, paymentMethod])
        return (
            <div className='overflow-hidden d-flex flex-grow-1 text-right position-relative'>
                <AdminPaymentsTable
                    year={year}
                    selectYear={selectYear}
                    selectPaymentMethod={selectPaymentMethod}
                    paymentMethod={paymentMethod}
                    payments={paymentsStore.payments}
                />
            </div>
        )
    }
)
