import React, { ReactElement } from 'react';
import { useField, useFormikContext } from 'formik';
import { Form } from '@foundation';
import { MKFormErrorMsg } from '@foundation-form';

interface IProps {
    name: string;
    label?: string | ReactElement;
    placeholder?: string;
    disabled?: boolean;
    readOnly?: boolean;
    type?: string;
    validate?: (value: string) => string | Promise<any>;
    helperText?:string;
}

export const MKFormField: React.FC<IProps> = ({ helperText,validate, type = 'text', readOnly, disabled, placeholder, label, name }) => {
    const [field, meta, helpers] = useField({ name, type, validate });
    const formik = useFormikContext();

    return (
        <Form.Group className='mb-3 text-right'>
            {label && <Form.Label className='d-flex text-right'>{label}</Form.Label>}

            <Form.Control type={type} isValid={meta.touched && !meta.error} {...field} onChange={(e) => helpers.setValue(e.target.value)} readOnly={readOnly} placeholder={placeholder}
                disabled={disabled || formik.isSubmitting} />
            <Form.Text className="text-muted">
                {helperText}
            </Form.Text>
            <MKFormErrorMsg name={field.name} />
        </Form.Group>
    )
}
