import React from "react";
import {observer} from "mobx-react";
import {
    PaymentMethodItem,
    EditPaymentMethodDetailsModal,
    AddPaymentMethodModal, PaymentMethodSelector, AddPaymentForUserModal
} from "@components";
import {useParams, useHistory} from "@router";
import {IPaymentMethodDTO, PaymentMethodStore, usePaymentMethodsStore} from "@store";
import {Button, Col, Container, Row, Stack} from "@foundation";
import {useMKModal} from "@foundation-ui";

export const AdminPaymentsManagerPage: React.FC = observer(() => {

    const {id} = useParams<{ id: string }>();
    const history = useHistory()
    const paymentMethodStore = usePaymentMethodsStore()
    const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethodStore>()
    const {
        isOpen: isOpenEditModal,
        openModal: openEditModal,
        closeModal: closeEditModal,
        modalData
    } = useMKModal<PaymentMethodStore>()
    const {
        isOpen: isAddModalOpen,
        openModal: openAddModal,
        closeModal: closeAddModal
    } = useMKModal()

    const {
        isOpen: isAddPaymentForUserModalOpen,
        openModal: openAddPaymentForUserModal,
        closeModal: closeAddPaymentForUserModal
    } = useMKModal()


    React.useEffect(() => {
        if (id) {
            paymentMethodStore.getPaymentMethod(id, (paymentMethod) => {
                setPaymentMethod(paymentMethod)
            })
        }
    }, [id, paymentMethodStore])

    const handleOnChangePaymentMethod = (paymentMethodId: string) => {
        history.push(`/admin/payments-manager/${paymentMethodId}`)
    }
    const handleOnAddPaymentMethod = async (paymentMethod: Partial<IPaymentMethodDTO>) => {
        const paymentMethodId = await paymentMethodStore.addPaymentMethod(paymentMethod)
        history.push(`/admin/payments-manager/${paymentMethodId}`)
    }

    return (
        <>
            <div className='d-flex align-content-center flex-grow-1 flex-column p-4 text-right'>
                <h3 className='fw-bold'>ניהול תשלומים</h3>

                <Container>
                    <Stack gap={3}>
                        <Row className="justify-content-center align-items-center">
                            <Col xs={12} sm={6}>
                                <PaymentMethodSelector
                                    onChange={handleOnChangePaymentMethod}
                                    defaultValue={id}
                                    hideAllOption
                                />
                            </Col>
                        </Row>
                        <Row className="justify-content-center align-items-center">

                            <Col xs={12} sm={6}>
                                <div className="d-grid gap-2">
                                    <Button onClick={() => openAddModal()}>הוספת תשלום חדש</Button>
                                    <Button onClick={() => openAddPaymentForUserModal()}>הוספת תשלום למשתמש</Button>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-3 justify-content-center">
                            <Col xs={12} sm={6}>
                                {paymentMethod &&
                                    <PaymentMethodItem openEditModal={openEditModal} paymentMethod={paymentMethod}/>}
                            </Col>
                        </Row>
                    </Stack>

                </Container>
            </div>
            <EditPaymentMethodDetailsModal open={isOpenEditModal} onClose={closeEditModal} paymentMethod={modalData}/>
            <AddPaymentMethodModal open={isAddModalOpen} onClose={closeAddModal}
                                   onSubmit={handleOnAddPaymentMethod}/>
            <AddPaymentForUserModal open={isAddPaymentForUserModalOpen} onClose={closeAddPaymentForUserModal}
            />
        </>

    );
});
