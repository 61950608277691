import React from 'react';
import {Button} from "@foundation";
import {useFormikContext} from "formik";

interface IProps {
    text:string;
}
export const MKFormSubmitButton: React.FC<IProps> = ({text}) => {
    const formik = useFormikContext();
    return (
        <Button disabled={formik.isSubmitting} className='w-100 text-white' type='submit' variant='primary' >
            {text}
        </Button>
    )
}
