import React from 'react'
import {observer} from "mobx-react";
import {UserStore} from "@store";
import {MKTable} from "@foundation-ui";
import {Form} from "@foundation";

import {Button} from "react-bootstrap";
import {UserMemberShipPaymentStatus} from "../user/UserMemberShipPaymentStatus";

interface IProps {
    users: UserStore[]
    title: string;
    openUserModal?: (user: UserStore) => void
}

export const UsersTable: React.FC<IProps> = observer(({title, users, openUserModal}) => {

    const EditSpecialTrainings = ({user, onUpdate}: { user: UserStore, onUpdate: (value: string) => void }) => {
        return (
            <Form.Control onChange={(e) => {
                user.updateUserDetails({id: user.id, karateDetails: {...user.karateDetails, specialTrainings: e.target.value}})
                onUpdate(e.target.value)
            }} placeholder={user.karateDetails.specialTrainings}/>

        )
    }

    const columns = [
        {
            dataField: 'id',
            text: '#',
            formatter: (col: any, row: UserStore, rowIndex: number) => <div>{rowIndex + 1}</div>,
            editable: false,
            csvFormatter: (cell: any, row: UserStore, rowIndex: number) => rowIndex + 1,
            csvExport: false,

        },
        {
            dataField: 'firstName',
            text: 'שם',
            editable: false
        },
        {
            dataField: 'lastName',
            text: 'שם משפחה',
            editable: false
        },
        {
            dataField: 'gender',
            text: 'מגדר',
            editable: false
        },
        {
            dataField: 'karateDetails.rank.name',
            text: 'דרגה',
            editable: false
        },
        {
            dataField: 'karateDetails.dojo.name',
            text: "דוג'ו",
            editable: false
        },
        {
            dataField: 'birthDate',
            text: "תאריך לידה",
            editable: false
        },
        {
            dataField: 'karateDetails.specialTrainings',
            text: "אימונים מיוחדים",
            editable: true,
            editorRenderer: (editorProps: any, value: string, row: UserStore, column: any, rowIndex: number, columnIndex: number) =>
                <EditSpecialTrainings onUpdate={editorProps.onUpdate} user={row}/>,
            csvExport: false,

        },
        {
            dataField: 'nutrition',
            text: "תזונה",
            editable: false,

        },
        {
            dataField: 'membershipPayments',
            text: "דמי חבר",
            formatter: (cell: any, row: UserStore) => <UserMemberShipPaymentStatus user={row}/>,
            editable: false,
            csvFormatter: (cell: any, row: UserStore, rowIndex: number) => `דמי חבר : ${row.membershipStatus ? 'שולם' : 'לא שולם'} לשנת ${new Date().getFullYear()}`,
            csvExport: false,

        },
        {
            dataField: 'moreDetails',
            text: "מידע נוסף",
            formatter: (cell: any, row: UserStore) => <Button className='text-white' onClick={() => openUserModal(row)}>מידע נוסף</Button>,
            editable: false,
            csvExport: false,

        },
    ];

    return (
        <MKTable<UserStore> title={title} keyField='id' data={users} columns={columns}/>
    )
})
