import React, {ReactElement} from 'react';
import BootstrapTable from "react-bootstrap-table-next";
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {observer} from "mobx-react";
import {Navbar, Nav, Button} from '@foundation';
import {Container} from "react-bootstrap";


interface IProps<T extends object = any> {
    columns: any
    data: T[]
    emptyText?: string;
    keyField: string;
    title?: string;
    isExpandRow?: boolean
    expandRowRender?: (row: T) => ReactElement
    onExpand?: (row: T) => void
    renderSecondHeader?: () => ReactElement
    renderHeaderButtons?:()=>ReactElement
}


export const MKTable = observer(<T extends object = any>({
                                                             onExpand,
                                                             renderSecondHeader,
                                                             isExpandRow = false,
                                                             expandRowRender,
                                                             data,
                                                             keyField,
                                                             columns,
                                                             title,
                                                             renderHeaderButtons,
                                                             emptyText = 'אין נתונים'
                                                         }: IProps<T>) => {
        const expandRow = {
            renderer: isExpandRow ? (row: T) => expandRowRender ? expandRowRender(row) : null : null,
            showExpandColumn: isExpandRow,
            expandByColumnOnly: isExpandRow,
            expandHeaderColumnRenderer: isExpandRow ? (): any => null : null,
            expandColumnRenderer: isExpandRow ? ({expanded}: { expanded: boolean }) => {
                if (expanded) {
                    return (
                        <b>-</b>
                    );
                }
                return (
                    <b>...</b>
                );
            } : null,
            onExpand: async (row: T) => onExpand ? await onExpand(row) : null
        };


        return (


            <ToolkitProvider
                columns={columns}
                data={data}
                keyField={keyField}
                exportCSV={{
                    fileName: `${title}.csv`,
                    noAutoBOM: false,
                }}
            >
                {(props) =>
                    <div className='d-flex flex-column text-right flex-grow-1' style={{minHeight: 0}}>
                        <Navbar className='flex-column align-items-stretch' bg="primary" variant="light">
                            <Container fluid>
                                <Navbar.Brand className='text-white'>{title}</Navbar.Brand>
                                <div className='d-flex gap-2'>
                                    {data.length > 1 && <Button className='text-white' variant="secondary" onClick={() => props.csvProps.onExport()}>יצא לאקסל</Button>}
                                    {renderHeaderButtons && renderHeaderButtons()}
                                </div>
                                <Nav className="me-auto ">
                                    <Navbar.Brand className='text-white'>{data.length}</Navbar.Brand>
                                </Nav>
                            </Container>
                            <div className='d-flex flex-grow-1'>
                                {renderSecondHeader && renderSecondHeader()}
                            </div>
                        </Navbar>
                        <BootstrapTable<T>
                            {...props.baseProps}
                            columns={columns}
                            data={data}
                            keyField={keyField}
                            cellEdit={cellEditFactory({mode: 'click'})}
                            expandRow={expandRow}
                            noDataIndication={emptyText}/>
                    </div>
                }
            </ToolkitProvider>


        )
    }
)








