import React from 'react'
import {Card} from '@foundation'
import {observer} from "mobx-react";
import {UserStore} from "@store";
import {UserProfileImageIcon} from '@assets';
import {UserMemberShipPaymentStatus,UploadUserProfilePicModal} from "@components";
import {useMKModal} from "@foundation-ui";

interface IProps {
    user: UserStore
}

const userProfilePicStyle: React.CSSProperties = {

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 150,
    height: 150,
    border: '1px solid rgba(0, 0, 0, 0.125)',
    marginLeft: 20,
    padding:10,
    cursor:'pointer'

}

export const UserMainDetailsCard: React.FC<IProps> = observer(({user}) => {

    const {isOpen, modalData, openModal, closeModal} = useMKModal<UserStore>()


    const renderUserProfilePic = ()=>{
        if(user.profilePicUrl){
            return (

                    <img
                        width={150}
                        height={150}
                        alt={`${user.fullName}-profile-pic`}
                        src={user.profilePicUrl}
                    />

            )
        }
        return (
            <div style={userProfilePicStyle}>
                <div onClick={()=>openModal(user)} className='p-2 d-flex flex-column align-items-center justify-content-center'>
                    <h4>
                        <UserProfileImageIcon/>
                    </h4>
                    <h6 className='text-center'>לחץ להעלאת תמונה</h6>
                </div>
            </div>
        )
    }

    return (
        <>
            <Card className='mb-3 '>
                <Card.Body>
                    <div className='gap-3 d-flex align-items-center flex-wrap justify-content-center  justify-content-sm-start'>
                        {renderUserProfilePic()}
                        <div className='text-center text-sm-end'>
                            <h3 className=' mb-0 fw-bolder'>{user.fullName}</h3>
                            <h4 className=' mb-0'>
                                <small className='text-muted'>
                                    דרגה : {user.karateDetails?.rank?.name}
                                </small></h4>
                            <h4 className=' mb-0'>
                                <small className='text-muted'>
                                    דוג׳ו : {user.karateDetails?.dojo?.name}
                                </small></h4>
                            <h5>
                                <small className='text-muted'>
                                    מזהה :{user.id}
                                </small>
                            </h5>
                            <UserMemberShipPaymentStatus user={user}/>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <UploadUserProfilePicModal open={isOpen} user={modalData} onClose={closeModal} />
        </>


    )
})
