import React, {useState} from 'react'
import {
    usePaymentMethodsStore,
    PaymentMethodStore,
    useAuthStore
} from "@store";
import {Container, Spinner, Accordion, Button, Row, Col, useAccordionButton} from '@foundation'
import {PaymentCard, PaymentModal, UserMemberShipPaymentStatus} from "@components";
import {observer} from "mobx-react";
import {useMKModal} from "@foundation-ui";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";

function AccordionCustomButton({
                                   children,
                                   disabled,
                                   eventKey
                               }: { children: React.ReactNode, disabled: boolean, eventKey: string }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
    });

    return (
        <h2 className='accordion-header'>
            <Accordion.Button className='gap-2' as={Button} variant='link' onClick={decoratedOnClick}
                              disabled={disabled}>
                {children}
            </Accordion.Button>
        </h2>

    );
}

export const PaymentsPage: React.FC = observer(() => {

    const paymentMethodsStore = usePaymentMethodsStore()
    const authStore = useAuthStore()
    const [loading, setLoading] = useState(false)
    const {
        isOpen: isPaymentModalOpen,
        modalData: paymentModalData,
        openModal: openPaymentModal,
        closeModal: closePaymentModal
    } = useMKModal<PaymentMethodStore>(false)

    const auth = useAuthStore()


    return (
        <PayPalScriptProvider
            options={{
                "client-id": process.env.REACT_APP_PAYPAL_KEY,
                locale: 'he_IL',
                currency: 'ILS',
                intent: 'capture'
            }}>
            <div className='overflow-auto'>

                <Container className='text-right d-flex flex-column py-4'>
                    <h1>תשלומים</h1>
                    <Accordion className='w-100' defaultActiveKey="0">
                        <Accordion.Item eventKey='0'>
                            <AccordionCustomButton eventKey='0'
                                                   disabled={!paymentMethodsStore.generalPaymentMethods.length}>
                                תשלומים כללים
                            </AccordionCustomButton>
                            <Accordion.Body>
                                {loading ? <Spinner animation="border"/> : <Row>
                                    {paymentMethodsStore.generalPaymentMethods.map((paymentMethod) => {
                                        return (
                                            <Col className='p-2' lg={4} md={6} sm={12} xs={12}
                                                 key={paymentMethod.id}>
                                                <PaymentCard onClick={openPaymentModal} setLoading={setLoading}
                                                             paymentMethod={paymentMethod}/>
                                            </Col>

                                        )
                                    })}
                                </Row>}
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey='1'>

                            <AccordionCustomButton eventKey='1'
                                                   disabled={!paymentMethodsStore.memberShipPaymentMethods.length || authStore.user.membershipStatus}>
                                תשלומי דמי חבר {auth.user.membershipStatus &&
                                <UserMemberShipPaymentStatus user={auth.user}/>}
                            </AccordionCustomButton>


                            <Accordion.Body>
                                {loading ? <Spinner animation="border"/> : <Row>
                                    {paymentMethodsStore.memberShipPaymentMethods.map((paymentMethod) => {
                                        return (
                                            <Col className='p-2' lg={4} md={6} sm={12} xs={12}
                                                 key={paymentMethod.id}>
                                                <PaymentCard onClick={openPaymentModal} setLoading={setLoading}
                                                             paymentMethod={paymentMethod}
                                                             disabledText={authStore.user.membershipStatus ? 'דמי חבר שולמו' : null}/>
                                            </Col>

                                        )
                                    })}
                                </Row>}
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey='2'>

                            <AccordionCustomButton eventKey='2'
                                                   disabled={!paymentMethodsStore.specialTrainingPaymentMethods.length}>
                                תשלומי אימונים מיוחדים
                            </AccordionCustomButton>

                            <Accordion.Body>
                                {loading ? <Spinner animation="border"/> : <Row>
                                    {paymentMethodsStore.specialTrainingPaymentMethods.map((paymentMethod) => {
                                        return (
                                            <Col className='p-2' lg={4} md={6} sm={12} xs={12}
                                                 key={paymentMethod.id}>
                                                <PaymentCard onClick={openPaymentModal} setLoading={setLoading}
                                                             paymentMethod={paymentMethod}/>
                                            </Col>

                                        )
                                    })}
                                </Row>}
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </Container>
                <PaymentModal paymentMethod={paymentModalData}
                              open={isPaymentModalOpen} onClose={closePaymentModal}/>
            </div>
        </PayPalScriptProvider>
    )
})
