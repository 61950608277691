import React, {useState} from 'react'
import {Card, Col, Form, Row} from '@foundation'
import {observer} from "mobx-react";
import {UserStore} from "@store";
import {EditUserPersonalDetailsModal} from "./modals/EditUserPersonalDetailsModal";
import {Button} from "react-bootstrap";

interface IProps{
    user:UserStore
}

export const UserPersonalDetailsCard: React.FC<IProps> = observer(({user}) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <Card className='mb-3 text-right'>
                <Card.Body>
                    <div className='mb-4 d-flex align-items-center justify-content-between'>
                        <h4  className='fw-bold'>פרטים אישיים</h4>
                        <Button size='sm' variant="outline-primary" onClick={()=>setOpen(true)}>
                            ערוך
                        </Button>
                    </div>
                    <Row>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Form.Group className='mb-3'>
                                <Form.Label>שם פרטי</Form.Label>
                                <Form.Control readOnly value={user.firstName}/>
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Form.Group className='mb-3'>
                                <Form.Label>שם משפחה</Form.Label>
                                <Form.Control readOnly value={user.lastName}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Form.Group className='mb-3'>
                                <Form.Label>תאריך לידה</Form.Label>
                                <Form.Control readOnly value={user.birthDate}/>
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Form.Group className='mb-3'>
                                <Form.Label>גיל</Form.Label>
                                <Form.Control readOnly value={user.age}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Form.Group className='mb-3'>
                                <Form.Label>תעודת זהות</Form.Label>
                                <Form.Control readOnly value={user.idNumber}/>
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Form.Group className='mb-3'>
                                <Form.Label>טלפון</Form.Label>
                                <Form.Control readOnly value={user.phoneNumber}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Form.Group className='mb-3'>
                                <Form.Label>מגדר</Form.Label>
                                <Form.Control readOnly value={user.gender}/>
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Form.Group className='mb-3'>
                                <Form.Label>תזונה</Form.Label>
                                <Form.Control readOnly value={user.nutrition}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label>מייל</Form.Label>
                                <Form.Control readOnly value={user.email}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label>עיר</Form.Label>
                                <Form.Control readOnly value={user.address.city}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label>רחוב</Form.Label>
                                <Form.Control readOnly value={user.address.street}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label>מספר בית</Form.Label>
                                <Form.Control readOnly value={user.address.streetNumber}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <EditUserPersonalDetailsModal open={open} onClose={()=>setOpen(false)} user={user}/>
        </>

    )
})
