import React, {useState} from 'react'
import {observer} from "mobx-react";
import {useAuthStore} from "@store";
import {MKForm, MKFormErrorGlobalMsg, MKFormField, MKFormSubmitButton} from '@foundation-form';
import {FormikHelpers} from "formik";
import {AuthError} from "@api";
import {Alert} from "react-bootstrap";
import {NavLink} from "@router";

interface IRecoverPasswordInitialValues {
    email: string;
}

export const ResetPassword: React.FC = observer(() => {

    const authStore = useAuthStore()
    const initialValues: IRecoverPasswordInitialValues = {
        email: '',
    }
    const [successMsg, setSuccessMsg] = useState('')
    const onSubmit = async (values: IRecoverPasswordInitialValues, formikHelpers: FormikHelpers<any>) => {
        try {
            setSuccessMsg('')
            await authStore.doResetPassword(values.email)
            setSuccessMsg('בדקות הקרובות ישלח מייל לאיפוס סיסמה')
        } catch (e) {
            if (e instanceof AuthError) {
                formikHelpers.setErrors({globalError: e.message})
            } else {
                formikHelpers.setErrors({globalError: 'שגיאה'})
            }
        }

    }

    return (
        <div>
            <MKForm<IRecoverPasswordInitialValues> initialValues={initialValues} onSubmit={onSubmit}>
                {() => {
                    return (
                        <>
                            <MKFormField placeholder='כתובת מייל הרשומה במערכת' label='מייל' validate={(value) => {
                                if (!value) {
                                    return 'שדה חובה'
                                }
                            }} name='email'/>
                            <MKFormSubmitButton text='שיחזור סיסמה'/>
                            <MKFormErrorGlobalMsg/>
                            {successMsg && <Alert className='mt-3 text-center' variant='success'>{successMsg}</Alert>}
                        </>
                    )
                }}

            </MKForm>
            <hr/>
            <div className='mt-2 text-center'>
                <NavLink className='text-primary' to='/login'>חזרה לכניסה</NavLink>
            </div>
        </div>


    )
})
