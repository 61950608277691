import React from 'react'
import {observer, Observer} from "mobx-react";
import {PaymentStore} from "@store";
import {MKTable} from "@foundation-ui";
import {AdminPaymentsYearSelector} from "./AdminPaymentsYearSelector";
import {FileIcon} from "@assets";
import {Col, Form, Row} from "@foundation";
import { PaymentMethodSelector } from '@components';


interface IProps {
    payments: PaymentStore[]
    selectYear: (year: string) => void
    selectPaymentMethod: (year: string) => void
    year: string;
    paymentMethod: string;
}

export const AdminPaymentsTable: React.FC<IProps> = observer(({
                                                                  selectPaymentMethod,
                                                                  paymentMethod,
                                                                  selectYear,
                                                                  year,
                                                                  payments
                                                              }) => {


    const columns = [
        {
            dataField: 'id',
            text: '#',
            editable:false,
            formatter:(col:any,row:PaymentStore,rowIndex:number)=><div>{rowIndex+1}</div>

        },
        {dataField: 'paymentMethodName', text: 'שולם על', editable:false},
        {
            dataField: 'date',
            text: 'תאריך',
            editable:false,
            formatter: (cell: any, row: PaymentStore) => <div>{row.date}</div>
        },
        {
            dataField: 'time',
            text: 'שעה',
            editable:false,
            formatter: (cell: any, row: PaymentStore) => <div>{row.time}</div>
        },
        {
            dataField: 'fullName',
            text: 'שם משלם',
            editable:false,
        },
        {
            dataField: 'type',
            text: 'סוג תשלום',
            editable: false,
            formatter: (cell: any, row: PaymentStore) => {

                if (row.type === 'direct-debit') {
                    return <div>הוראת קבע</div>
                }
                if (row.type === 'check') {
                    return <div>צ'ק</div>
                }
                if (row.type === 'cash') {
                    return <div>מזומן</div>
                }
                return <div>{row.type}</div>
            }
        },
        {
            dataField: 'amount',
            text: 'סכום',
            editable:false,
        },
        {
            dataField: 'rivhitDocLink',
            text: 'קבלה',
            csvExport: false,
            editable:false,
            formatter: (cell: any, row: PaymentStore) => <a target='_blank' rel='noreferrer'
                                                            href={row.rivhitDocLink}><FileIcon/></a>
        },
    ]

    const expandRowRender = (payment: PaymentStore) => {
        return (<Observer>{() => <div>

            <Row>
                <Col xs={4}>
                    <Form.Group className='mb-3'>
                        <Form.Label>שם פרטי</Form.Label>
                        <Form.Control readOnly value={payment.user?.firstName}/>
                    </Form.Group>
                </Col>
                <Col xs={4}>
                    <Form.Group className='mb-3'>
                        <Form.Label>שם משפחה</Form.Label>
                        <Form.Control readOnly value={payment.user?.lastName}/>
                    </Form.Group>
                </Col>
                <Col xs={4}>
                    <Form.Group className='mb-3'>
                        <Form.Label>מייל</Form.Label>
                        <Form.Control readOnly value={payment.user?.email}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='mb-3'>
                        <Form.Label>מזהה תשלום</Form.Label>
                        <Form.Control readOnly value={payment?.id}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='mb-3'>
                        <Form.Label>מזהה משתמש</Form.Label>
                        <Form.Control readOnly value={payment?.user?.id}/>
                    </Form.Group>
                </Col>
                {payment.paypalDetails.payerId &&    <Col>
                    <Form.Group className='mb-3'>
                        <Form.Label>מספר משלם - paypal</Form.Label>
                        <Form.Control readOnly value={payment?.paypalDetails?.payerId}/>
                    </Form.Group>
                </Col>}
                {payment.paypalDetails.orderId &&    <Col>
                    <Form.Group className='mb-3'>
                        <Form.Label>מספר תשלום - paypal</Form.Label>
                        <Form.Control readOnly value={payment?.paypalDetails?.orderId}/>
                    </Form.Group>
                </Col>}
            </Row>


        </div>}
        </Observer>)
    }
    const onExpand = async (payment: PaymentStore) => {

        await payment.getUserDetails()

    }
    const renderHeader = () => {


        return (

            <div className='p-2' style={{flex: 1, display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: 10}}>
                <AdminPaymentsYearSelector onChange={selectYear} defaultValue={year}/>
                <PaymentMethodSelector onChange={selectPaymentMethod} defaultValue={paymentMethod}/>
            </div>

        )

    }


    return (


        <MKTable<PaymentStore> renderHeader={renderHeader} onExpand={onExpand} expandRowRender={expandRowRender}
                               isExpandRow title="תשלומים"
                               keyField='id'
                               data={payments} columns={columns}/>

    )
})
