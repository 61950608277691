import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import {FIREBASE_CONFIG} from "../config";


export class FirebaseService {

    public usersCollection: firebase.firestore.CollectionReference;
    public dojosCollection: firebase.firestore.CollectionReference;
    public ranksCollection: firebase.firestore.CollectionReference;
    public paymentsCollection: firebase.firestore.CollectionReference;
    public paymentMethodsCollection: firebase.firestore.CollectionReference;
    public nfcDevicesCollection: firebase.firestore.CollectionReference;

    public auth: firebase.auth.Auth

    public async connect(): Promise<any> {
        if (!firebase.apps.length) {
            firebase.initializeApp(FIREBASE_CONFIG)
        }
        const db = firebase.firestore()
        this.auth = firebase.auth()
        this.usersCollection = db.collection('users')
        this.dojosCollection = db.collection('dojos')
        this.ranksCollection = db.collection('ranks')
        this.paymentsCollection = db.collection('payments')
        this.paymentMethodsCollection = db.collection('paymentMethods')
        this.nfcDevicesCollection = db.collection('nfcDevices')
        return
    }

    public async token(): Promise<string | null> {
        return await this.auth.currentUser?.getIdToken() ?? null
    }

    public authChange(cb: (user: firebase.User) => void): void {
        this.auth.onAuthStateChanged((user) => {
            cb(user)
        }, (e) => {
            console.log(e);
        })
    }

    public async resetPassword(email: string): Promise<void> {
        return await this.auth.sendPasswordResetEmail(email)
    }

    public async signOut() {
        await this.auth.signOut()
    }

    public async signInWithEmailAndPassword(email: string, password: string) {

        return await this.auth.signInWithEmailAndPassword(email, password)

    }

    public async createUserWithEmailAndPassword(email: string, password: string): Promise<string> {
        const userCredential = await this.auth.createUserWithEmailAndPassword(email, password)
        await userCredential.user.sendEmailVerification()
        const user = userCredential.user;
        return user.uid
    }

    public storage(): firebase.storage.Reference {
        return firebase.storage().ref()
    }

}
