import {makeAutoObservable, runInAction} from "mobx";
import {RootStore, UserStore} from "@store";
import {IApi} from "@api";
import {IUserDTO} from "./UserStore";

export class AuthStore {

    public loggedIn: boolean
    public user: UserStore;
    public emailNotVerified: boolean

    constructor(private rootStore: RootStore, private api: IApi) {
        this.loggedIn = false
        makeAutoObservable(this)
    }

    public async doLogin(email: string, password: string): Promise<void> {
        try {
            await this.api.doLogin(email, password)
        } catch (e) {
            console.log(e);
            throw e
        }
    }

    public async doLogout(): Promise<void> {

        await this.api.doLogout()
        runInAction(() => {
            this.loggedIn = false
        })

    }

    public set setLoggedInStatus(status: boolean) {
        runInAction(() => {
            this.loggedIn = status
        })
    }

    public async doResetPassword(email: string) {
        try {
            await this.api.resetPassword(email)
        } catch (e) {
            throw e
        }
    }

    public async doRegister(user: IUserDTO): Promise<void> {
        await this.api.doRegister(user)
    }

    public async getUserDetails(id: string) {

        await this.api.getUserDetails(id, (userDTO) => {

            runInAction(() => {

                this.user = new UserStore(this.api).init(userDTO)
            })

        })
        return

    }

    public set setEmailNotVerified(status: boolean) {
        this.emailNotVerified = status
    }

}

