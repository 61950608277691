import React from 'react';
import {AuthStore, DojosStore, PaymentMethodsStore, PaymentsStore, RanksStore, RootStore} from "@store";
import {IStoreContextValue, StoreContext} from "./StoreProvider";
import {UsersStore} from "./UsersStore";
import {NfcDevicesStore} from "./NfcDevicesStore";

export function useRootStore(): RootStore {
    const appContextValue = React.useContext<IStoreContextValue>(StoreContext);
    return appContextValue.rootStore;
}

export function useAuthStore(): AuthStore {
    return useRootStore().authStore
}

export function usePaymentMethodsStore(): PaymentMethodsStore {
    return useRootStore().paymentMethodsStore
}


export function usePaymentsStore(): PaymentsStore {
    return useRootStore().paymentsStore
}

export function useRanksStore(): RanksStore {
    return useRootStore().ranksStore
}

export function useDojosStore(): DojosStore {
    return useRootStore().dojosStore
}

export function useUsersStore(): UsersStore {
    return useRootStore().usersStore
}


export function useNfcDevicesStore(): NfcDevicesStore {
    return useRootStore().nfcDevicesStore
}





