import React, {useMemo} from 'react'
import {observer} from "mobx-react";
import {Form, Select} from "@foundation";
import {usePaymentMethodsStore} from "@store";

interface IProps {
    name?: string
    onChange: (value: string) => void
    defaultValue: string;
    hideAllOption?:boolean
}

export const PaymentMethodSelector: React.FC<IProps> = observer(({name, onChange,hideAllOption, defaultValue}) => {

    const paymentMethodsStore = usePaymentMethodsStore()
    const paymentMethodsForSelector: { value: string, label: string }[] = useMemo(() => {

        return paymentMethodsStore.paymentMethods.map((paymentMethod) => {
            return {
                value: paymentMethod.id,
                label: paymentMethod.name
            }
        })

    }, [paymentMethodsStore.paymentMethods])
    const paymentMethodsForSelectorArray: { value: string, label: string }[] = useMemo(() => {

        if(!hideAllOption){
            return [{value: 'all', label: 'הכל'}, ...paymentMethodsForSelector]
        }
        return paymentMethodsForSelector

    }, [hideAllOption,paymentMethodsForSelector])


    const value = useMemo(() => {
        return paymentMethodsForSelectorArray.find(item => item.value === defaultValue)
    }, [paymentMethodsForSelectorArray, defaultValue])
    return (
        <Form.Group className='text-right'>
            <Form.Label className='d-flex'>תשלום</Form.Label>
            <Select
                value={value}
                placeholder='תשלום'
                isRtl
                isSearchable
                name={name}
                options={paymentMethodsForSelectorArray}
                onChange={(selectedItem) => onChange(selectedItem.value)}
            />
        </Form.Group>
    )
})
