import React from 'react'
import {observer} from "mobx-react";
import {UserStore} from "@store";
import {MKModal} from "@foundation-ui";
import {UserDetails} from "@components";

interface IProps {
    user: UserStore;
    open: boolean
    onClose: () => void;
}


export const UserModal: React.FC<IProps> = observer(({user, open, onClose}) => {


    return (
        <MKModal
            size='lg'
            title={user?.fullName}
            showCloseButton
            open={open}
            onClose={onClose}
            render={() => {
                return (
                    <div className='p-4'>

                        <UserDetails user={user}/>
                    </div>
                )
            }}
                />

                )
            })
