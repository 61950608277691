import React from "react";
import {observer} from "mobx-react";
import {useNfcDevicesStore} from "@store";
import {AdminNfcDevices} from "@components";
import {Col, Row} from "@foundation";
export const AdminNfcDevicesPage: React.FC = observer(() => {

    const nfcDevicesStore = useNfcDevicesStore()
    React.useEffect(() => {
        nfcDevicesStore.getNfcDevices()
    }, [nfcDevicesStore])

    return (
            <div className='d-flex align-content-center flex-grow-1 flex-column p-4 text-right'>
                <h3 className='fw-bold'>ניהול כרטיסים</h3>
                <Row>
                    <Col xs={12} sm={6}>
                        <AdminNfcDevices/>
                    </Col>
                </Row>
            </div>
    );
});
