import React, { createContext } from 'react';
import {RootStore} from "@store";

export interface IStoreContextValue {
    rootStore: RootStore;
}

export const StoreContext = createContext<IStoreContextValue>(null)

export const Provider: React.FC<{ rootStore: RootStore }> = (props) => {
    const { children, rootStore } = props;
    return <StoreContext.Provider value={{ rootStore }}>{children}</StoreContext.Provider>;
};


