import {RootStore} from "./RootStore";
import {IApi} from "@api";
import {IPaymentMethodDTO, PaymentMethodStore} from "@store";
import {makeAutoObservable, runInAction} from "mobx";

export class PaymentMethodsStore {

    paymentMethods: PaymentMethodStore[] = []

    constructor(private rootStore: RootStore, private api: IApi) {
        makeAutoObservable(this)
    }

    public getPaymentMethods(): void {
        if (!this.paymentMethods.length) {
            return this.api.getPaymentMethods((paymentMethod) => {
                runInAction(() => {
                    this.paymentMethods.push(new PaymentMethodStore(this.api, paymentMethod))
                })
            }, () => {
            }, () => {
            }, (paymentsMethods) => {
                runInAction(() => {

                    this.paymentMethods = paymentsMethods.map(paymentMethodDTO => new PaymentMethodStore(this.api, paymentMethodDTO))
                })
            })
        }
    }

    public getPaymentMethod(id: string, set: (paymentMethodStore: PaymentMethodStore) => void): void {

        return this.api.getPaymentMethod(id, (paymentMethodDTO) => {
            const paymentMethodStore = new PaymentMethodStore(this.api, paymentMethodDTO)
            set(paymentMethodStore)
        }, () => {
            runInAction(() => {

            })
        })
    }

    public findPaymentMethod(paymentMethodId: string): PaymentMethodStore {
       return this.paymentMethods.find(paymentMethod=>paymentMethod.id === paymentMethodId)
    }

    public get memberShipPaymentMethods(): PaymentMethodStore[] {

        return this.paymentMethods.filter(paymentMethod => paymentMethod.type === 'memberShip' && paymentMethod.enabled)

    }

    public get generalPaymentMethods(): PaymentMethodStore[] {

        return this.paymentMethods.filter(paymentMethod => paymentMethod.type === 'general' && paymentMethod.enabled)

    }

    public get specialTrainingPaymentMethods(): PaymentMethodStore[] {

        return this.paymentMethods.filter(paymentMethod => paymentMethod.type === 'specialTraining' && paymentMethod.enabled)

    }

    public async addPaymentMethod(paymentMethod: Partial<IPaymentMethodDTO>): Promise<string> {

        return this.api.addPaymentMethod(paymentMethod)

    }

}
