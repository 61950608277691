import React from 'react'
import {observer} from "mobx-react";


export const AdminPage: React.FC = observer(() => {



        return (
            <div >

            </div>
        )
    }
)
