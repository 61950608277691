import {makeAutoObservable, runInAction} from "mobx";
import {UserStore} from "./UserStore";
import {IApi} from "@api";

export interface IRankDTO {
    id: string;
    name: string;
    nameEng: string;
    members?: UserStore[]
}

export class RankStore implements IRankDTO {
    id: string;
    name: string;
    nameEng: string;
    members: UserStore[]

    constructor(rank: IRankDTO, private api?: IApi) {
        this.update(rank)
        makeAutoObservable(this)
    }

    public update(rank: IRankDTO) {
        this.id = rank.id;
        this.name = rank.name;
        this.nameEng = rank.nameEng
    }

    public async getMembers(): Promise<void> {
        const users = await this.api.getRankMembers(this.id)
        runInAction(() => {
            this.members = users.map((user) => {
                return new UserStore(this.api).init(user)
            })
        })

    }


}
