import React, {useMemo} from 'react'
import {observer} from "mobx-react";
import {Form, Select} from "@foundation";

interface IProps {
    name?: string
    onChange: (value: string) => void
    defaultValue: string;
}

export const AdminPaymentsYearSelector: React.FC<IProps> = observer(({name, onChange, defaultValue}) => {

    const max = new Date().getFullYear()
    const min = max - 4
    const years: string[] = []
    for (let i = max; i >= min; i--) {
        years.push(i.toString())
    }

    const yearsData: { value: string, label: string }[] = years.map((year: string) => {
        return {
            value: year,
            label: year
        }
    })
    const value = useMemo(() => {
        return yearsData.find(year => year.value === defaultValue)
    }, [yearsData, defaultValue])

    return (
        <Form.Group className='text-right'>
            <Form.Label className='d-flex'>שנה</Form.Label>
            <Select
                value={value}
                placeholder='דוג׳ו'
                isRtl
                isSearchable
                name={name}
                options={yearsData}
                onChange={(selectedItem) => onChange(selectedItem.value)}
            />
        </Form.Group>
    )
})
