import React from 'react'
import {observer} from "mobx-react";
import {PaymentStore, useAuthStore, UserStore} from "@store";
import {MKTable, useMKModal} from "@foundation-ui";
import {FileIcon} from "@assets";
import {Button} from "react-bootstrap";
import {AddPaymentForUserModal} from '../payments/modals/AddPaymentForUserModal';

interface IProps {
    user: UserStore
}

export const UserPaymentsTable: React.FC<IProps> = observer(({user}) => {

    const authStore = useAuthStore()
    const columns = [{dataField: 'paymentMethodName', text: 'שולם על', editable: false},
        {
            dataField: 'date',
            text: 'תאריך',
            editable: false,
            formatter: (cell: any, row: PaymentStore) => <div>{row.date}</div>
        },
        {
            dataField: 'time',
            text: 'שעה',
            editable: false,
            formatter: (cell: any, row: PaymentStore) => <div>{row.time}</div>
        },

        {
            dataField: 'amount',
            editable: false,
            text: 'סכום',
        },
        {
            dataField: 'type',
            text: 'סוג תשלום',
            editable: false,
            formatter: (cell: any, row: PaymentStore) => {

                if (row.type === 'direct-debit') {
                    return <div>הוראת קבע</div>
                }
                if (row.type === 'check') {
                    return <div>צ'ק</div>
                }
                if (row.type === 'cash') {
                    return <div>מזומן</div>
                }
                return <div>{row.type}</div>
            }
        },
        {
            dataField: 'rivhitDocLink',
            text: 'קבלה',
            csvExport: false,
            editable: false,
            formatter: (cell: any, row: PaymentStore) => <a target='_blank' rel='noreferrer'
                                                            href={row.rivhitDocLink}><FileIcon/></a>
        },
    ]
    const {
        isOpen: isAddPaymentForUserModalOpen,
        closeModal: closeAddPaymentForUserModal,
        openModal: openAddPaymentForUserModal,
        modalData: addPaymentForUserModalData
    } = useMKModal<UserStore>()

    return (
        <>
            <MKTable<PaymentStore> renderHeaderButtons={() => {

                if (authStore.user.isAdmin) {
                    return <Button onClick={() => openAddPaymentForUserModal(user)} variant='secondary'>הוסף
                        תשלום</Button>
                }
                return null


            }} title="תשלומים" keyField='id'
                                   data={user.payments} columns={columns}/>
            <AddPaymentForUserModal disableUserSelector formData={{user: addPaymentForUserModalData}}
                                    onClose={closeAddPaymentForUserModal} open={isAddPaymentForUserModalOpen}/>
        </>

    )
})
