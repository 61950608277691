import React, {useEffect} from 'react'
import {observer} from "mobx-react";
import {UserPersonalDetailsCard, UserKarateDetailsCard, UserMainDetailsCard, UserPaymentsTable} from "@components";
import {UserStore} from "@store";

interface IProps{
    user:UserStore
}
export const UserDetails: React.FC<IProps> = observer(({user}) => {

    useEffect(()=>{
        user.getPayments()
    },[user])

    return (
        <div className='overflow-auto'>
            <UserMainDetailsCard user={user}/>
            <UserPersonalDetailsCard user={user}/>
            <UserKarateDetailsCard user={user}/>
            <UserPaymentsTable user={user}/>
        </div>
    )
})
