import React, { useState } from 'react';

type HookedFuncResult<T> = {
    modalData: T | null;
    openModal: (data?: T) => void;
    closeModal: () => void;
    isOpen: boolean;
};

export const useMKModal = <T extends any>(open: boolean = false): HookedFuncResult<T> => {
    const [isOpen, setIsOpen] = useState(open);
    const [modalData, setModalData] = useState<T | null>(null);

    const openModal = React.useCallback((data: T): void => {
        setModalData(data);
        setIsOpen(true);
    }, []);

    const closeModal = React.useCallback(() => setIsOpen(false), []);

    return {
        isOpen,
        modalData,
        openModal,
        closeModal,
    };
};