import {RootStore} from "./RootStore";
import {IApi} from "@api";
import {makeAutoObservable, runInAction} from "mobx";
import {IPaymentType, IPaypalDetails, PaymentStore} from "@store";
import firebase from "firebase/app";

export interface ICreatePayment {
    paymentMethodId: string,
}
export interface ICreatePaypalPayment extends ICreatePayment{
    paypalDetails: IPaypalDetails
}
export interface ICreatePaymentForUser extends ICreatePayment{
    paymentMethodId: string,
    userId:string,
    rivhit:boolean,
    type: IPaymentType | '';
}

export class PaymentsStore {


    payments: PaymentStore[]
    paymentsLastDoc: firebase.firestore.DocumentData | null

    constructor(private rootStore: RootStore, private api: IApi) {
        this.payments = []
        this.paymentsLastDoc = null
        makeAutoObservable(this)

    }

    public async createPaypalPayment(paymentMethodId:string, paypalDetails: IPaypalDetails) {
        const payment: ICreatePaypalPayment = {
            paymentMethodId,
            paypalDetails
        }
        await this.api.createPaypalPayment(payment)

    }

    public async createNewPaymentForUser(payment: ICreatePaymentForUser) {
        await this.api.createPaymentForUser(payment)
    }

    public async getPayments(year: string, paymentMethod: string) {
        this.payments = []
        await this.api.getPayments((payments) => {
            runInAction(() => {
                this.payments = payments.map((paymentDTO) => {
                    return new PaymentStore(this.api).init(paymentDTO)
                })
            })
        }, paymentMethod, year, 15)
    }

}
