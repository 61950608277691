import {makeAutoObservable, runInAction} from "mobx";
import {UserStore} from "./UserStore";
import {IApi} from "@api";

export interface IDojoDTO {
    id: string;
    name: string
    users?: UserStore[]

}

export class DojoStore implements IDojoDTO {
    id: string;
    name: string
    users: UserStore[] = []

    constructor(dojo: IDojoDTO, private api?: IApi) {
        this.update(dojo)
        makeAutoObservable(this)

    }

    public update(dojo: IDojoDTO) {
        this.id = dojo.id;
        this.name = dojo.name
    }

    public async getUsers(): Promise<void> {
        const response = await this.api.getDojoMembers(this.id)
        const users = response.map((user) => {
            return new UserStore(this.api).init(user)
        })
        runInAction(() => {
            this.users = users
        })
    }
}
