import React, {useState} from 'react'
import {Card, Col, Form, Row} from '@foundation'
import {observer} from "mobx-react";
import {UserStore} from "@store";
import {Button} from "react-bootstrap";
import {EditUserKarateDetailsDetailsModal} from "@components";

interface IProps {
    user: UserStore
}

export const UserKarateDetailsCard: React.FC<IProps> = observer(({user}) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <Card className='mb-3 text-right'>
                <Card.Body>
                    <div className='mb-4 d-flex align-items-center justify-content-between'>
                        <h4 className='fw-bold'>פרטי קראטה</h4>
                        <Button size='sm' variant="outline-primary" onClick={() => setOpen(true)}>
                            ערוך
                        </Button>
                    </div>
                    <Row>
                        <Col lg={4} md={6} sm={6} xs={12}>
                            <Form.Group className='mb-3'>
                                <Form.Label>דרגה</Form.Label>
                                <Form.Control readOnly value={user.karateDetails?.rank?.name}/>
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={12}>
                            <Form.Group className='mb-3'>
                                <Form.Label>שנת קבלת דרגה</Form.Label>
                                <Form.Control readOnly value={user.karateDetails?.rankYear}/>
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={12}>
                            <Form.Group className='mb-3'>
                                <Form.Label>וותק בדרגה</Form.Label>
                                <Form.Control readOnly value={user.seniorityRank}/>
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Form.Group className='mb-3'>
                                <Form.Label>דוג׳ו</Form.Label>
                                <Form.Control readOnly value={user.karateDetails?.dojo?.name}/>
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Form.Group className='mb-3'>
                                <Form.Label>שנת הצטרפות</Form.Label>
                                <Form.Control readOnly value={user.karateDetails?.joiningYear}/>
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Form.Group className='mb-3'>
                                <Form.Label>אימונים מיוחדים</Form.Label>
                                <Form.Control readOnly value={user.karateDetails?.specialTrainings}/>
                            </Form.Group>
                        </Col>
                    </Row>

                </Card.Body>
            </Card>
            <EditUserKarateDetailsDetailsModal open={open} onClose={() => setOpen(false)} user={user}/>
        </>
    )
})
