import React from 'react'
import {observer} from "mobx-react";
import {ICreatePaymentForUser, usePaymentMethodsStore, usePaymentsStore, UserStore} from "@store";
import {Form} from '@foundation'
import {MKModal} from "@foundation-ui";
import {Button, Col, Row} from "react-bootstrap";
import {MKForm} from "@foundation-form";
import {PaymentMethodSelector, PaymentTypeSelector, UsersSelector} from "@components";

interface IProps {
    open: boolean
    onClose: () => void;
    formData?: {
        user: UserStore
    }
    disableUserSelector?: boolean
}


export const AddPaymentForUserModal: React.FC<IProps> = observer(({
                                                                      open,
                                                                      onClose,
                                                                      formData,
                                                                      disableUserSelector
                                                                  }) => {

    const paymentMethodsStore = usePaymentMethodsStore()
    const paymentsStore = usePaymentsStore()

    const [amount, setAmount] = React.useState('')

    const initialValues: ICreatePaymentForUser = {
        paymentMethodId: '',
        userId: formData?.user?.id ?? '',
        rivhit: true,
        type: ''
    }
    const handleSubmit = async (values: ICreatePaymentForUser) => {
        await paymentsStore.createNewPaymentForUser(values)
        onClose()
    }

    return (
        <MKModal
            title='הוספת תשלום למשתמש'
            open={open}
            onClose={onClose}
            render={() => {
                return (
                    <MKForm<ICreatePaymentForUser>
                        onSubmit={handleSubmit}
                        initialValues={initialValues}>
                        {(formik) => {
                            return (
                                <div>
                                    <div className='p-3'>
                                        <Row>
                                            <Col xs={12}>
                                                <Form.Group className='mb-3 text-right'>
                                                    <UsersSelector disabled={disableUserSelector} defaultValue={formData.user}
                                                                   label='חיפוש משתמשים' onChange={(userId) => {
                                                        formik.setFieldValue('userId', userId)
                                                    }}/>
                                                </Form.Group>
                                            </Col>
                                            <Col className='mb-3' xs={12}>
                                                <PaymentTypeSelector
                                                    onChange={(paymentType) => {
                                                        formik.setFieldValue('type', paymentType)
                                                    }}
                                                />
                                            </Col>
                                            <Col className='mb-3' xs={12}>
                                                <PaymentMethodSelector
                                                    hideAllOption
                                                    defaultValue={''}
                                                    onChange={(paymentMethodId) => {
                                                        const paymentMethod = paymentMethodsStore.findPaymentMethod(paymentMethodId)
                                                        formik.setFieldValue('paymentMethodId', paymentMethodId)
                                                        setAmount(paymentMethod.amount)
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>סכום</Form.Label>
                                                    <Form.Control readOnly value={amount}/>
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check onChange={(event) => {
                                                formik.setFieldValue('rivhit', event.target.checked)
                                            }} name='rivhit' checked={formik.values.rivhit} type="checkbox"
                                                        label="הפקת קבלה עם רווחית"/>
                                        </Form.Group>
                                    </div>
                                    <div className='gap-2 p-3 border-top d-flex align-items-center justify-content-end'>
                                        <Button disabled={!formik.isValid} className='text-white ml-1'
                                                type='submit'>הוספה</Button>

                                        <Button variant='danger' className='text-white' onClick={onClose}>סגור</Button>
                                    </div>
                                </div>
                            )
                        }}
                    </MKForm>
                )
            }}
        />

    )
})
