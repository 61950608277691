import React from 'react';
import {Provider, RootStore} from '@store';
import {FirebaseService} from "@services";
import {AppLoader} from "./AppLoader";


const rootStore = new RootStore(new FirebaseService())
rootStore.connect()

const App: React.FC = () => {
    return (
        <Provider rootStore={rootStore}>
            <AppLoader/>
        </Provider>
    );
}
export default App;
